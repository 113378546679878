// Packages
import React, { useState } from "react";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Redux Operations
import { discountOperations } from "../../state/features/discount";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import {
  calculateAmountsAndBreakdowns,
  formatDecimal,
} from "../../helpers/itemCalculations";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";

interface IDiscountProps {
  discount: any;
  cart: any;
  currency: string;
  menu: any;
  orderType: any;
  loyalty: any;
  selectedArea: any;
  deliveryChargeId: any;
  riderTip: any;
  fetchDiscount: any;
  applyDiscount: any;
  removeDiscount: any;
  setCart: any;
}

function Discount(props: IDiscountProps) {
  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const { appliedPromotionsData, promotionCartBreakdowns } =
    usePromotionsContext();

  const intl = useIntl();

  const onDiscountCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountError("");
    setDiscountCode(e.target.value);
  };

  const removeDiscount = () => {
    setDiscountError("");
    setDiscountCode("");
    props.removeDiscount();
    updateCart(null);
  };

  const applyDiscount = () => {
    props.fetchDiscount(discountCode).then((response: any) => {
      if (response.error) {
        setDiscountError(
          intl.formatMessage({ id: "error.invalid_discount_code", defaultMessage: "Invalid Discount code" })
        );
      } else {
        validateDiscount(response.payload.data);
      }
    });
  };

  const validateDiscount = (discount: any) => {
    let maxdiscountableAmount = parseFloat(promotionCartBreakdowns.subTotal);
    if (discount.on_total) {
      maxdiscountableAmount += parseFloat(
        promotionCartBreakdowns.surchargeSubTotal
      );
    }

    let discountAmount;
    if (discount.discount_type == "PERCENTAGE") {
      discountAmount =
        (maxdiscountableAmount * parseFloat(discount.discount_rate)) / 100;
    } else {
      discountAmount = parseFloat(discount.discount_rate);
    }

    if (discountAmount > maxdiscountableAmount) {
      setDiscountError(
        intl.formatMessage(
          {
            id: "discount.validation.use_only_if_above_amount",
            defaultMessage:
              "This coupon can only be used for orders above {currency} {amount}!",
          },
          { currency: props.currency, amount: formatDecimal(discountAmount) }
        )
      );
      return;
    } else if (maxdiscountableAmount < parseFloat(discount.min_order_amount)) {
      setDiscountError(
        intl.formatMessage(
          {
            id: "discount.validation.use_only_if_above_amount",
            defaultMessage:
              "This coupon can only be used for orders above {currency} {amount}!",
          },
          {
            currency: props.currency,
            amount: formatDecimal(discount.min_order_amount),
          }
        )
      );
      return;
    }

    discount.discountedAmount = discountAmount;
    discount.maxdiscountableAmount = maxdiscountableAmount;
    props.applyDiscount(discount);
    updateCart(discount);
    setDiscountError("");
  };

  const updateCart = (discount: any) => {
    let cart = calculateAmountsAndBreakdowns(
      appliedPromotionsData.promotionAppliedCart,
      props.menu,
      props.orderType,
      discount,
      props.loyalty,
      props.selectedArea,
      props.deliveryChargeId,
      props.riderTip,
      appliedPromotionsData.orderPromotion
    ) as any;
    cart.items = props.cart.items;

    props.setCart(cart);
  };

  return (
    <React.Fragment>
      <div className="widget discount-sections">
        {props.discount ? (
          <div className="coupon-applied-section mb-2 ">
            <p className="coupon-name">
              <FormattedMessage
                id="discount.coupon_applied"
                defaultMessage="{discountName} <spanTag>coupon code applied</spanTag>"
                values={{
                  discountName: props.discount.discount_name,
                  spanTag: (text) => <span className="label">{text}</span>,
                }}
              />
            </p>
            <p className="coupon-info">
              {props.discount.discount_type == "PERCENTAGE" ? (
                <FormattedMessage
                  id="discount.percentage_off"
                  defaultMessage="{discountRate}% off"
                  values={{
                    discountRate: formatDecimal(props.discount.discount_rate),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="discount.value_off"
                  defaultMessage="{currency} {discountRate} off"
                  values={{
                    currency: props.currency,
                    discountRate: formatDecimal(props.discount.discount_rate),
                  }}
                />
              )}
            </p>
            <p className="remove-button" onClick={removeDiscount}>
              <FormattedMessage
                id="discount.remove_coupon"
                defaultMessage="Remove Coupon"
              />
            </p>
          </div>
        ) : (
          <div className="apply-coupon-section">
            <div className="form-group mb-2 w-100">
              <label className="input-label bold">
                <FormattedMessage
                  id="discount.do_you_have_a_coupon_code"
                  defaultMessage="Do you have a coupon code?"
                />
              </label>
              <div className="d-flex">
                <FormattedMessage
                  id="discount.enter_code"
                  defaultMessage="Enter code"
                >
                  {(placeholder) => (
                    <input
                      name="address_2"
                      type="text"
                      required
                      className="form-control discount-input"
                      placeholder={placeholder}
                      autoComplete="off"
                      value={discountCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onDiscountCodeChange(e)
                      }
                      dir="auto"
                    ></input>
                  )}
                </FormattedMessage>
                <button
                  className="btn px-4 ml-2 btn-primary"
                  onClick={applyDiscount}
                  disabled={!discountCode || discountError != ""}
                >
                  <FormattedMessage
                    id="global.apply"
                    defaultMessage="Apply"
                  />
                </button>
              </div>
            </div>
            {discountError && <p className="discount-error">{discountError}</p>}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let discount = state.discount;
  let cart = state.cart;
  let currency = state.company.currency;
  let menu = state.menu;
  let orderType = state.session.order_type;
  let loyalty = state.loyalty.redeemedPoints;
  let selectedArea = state.areas.selectedArea;
  let deliveryChargeId = state.outlet.delivery_charge_id;
  let riderTip = Number(state.riderTip);

  return {
    discount,
    cart,
    currency,
    menu,
    orderType,
    loyalty,
    selectedArea,
    deliveryChargeId,
    riderTip,
  };
};

const mapDispatchToProps = {
  fetchDiscount: discountOperations.fetchDiscount,
  applyDiscount: discountOperations.applyDiscount,
  removeDiscount: discountOperations.removeDiscount,
  setCart: cartOperations.setCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discount);
