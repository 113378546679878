import React, { useEffect, useMemo, useRef, useState } from "react";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import promotionAppliedIcon from "../../images/promotion-applied-icon.svg";

const AppliedPromotionsModalMobile = () => {
  const { newlyAppliedPromotions, userAcknowledgedPromotionsHandler } =
    usePromotionsContext();
  const [show, setShow] = useState(false);
  const currentAppliedPromotionRef = useRef<null|any>(null);

  useEffect(() => {
    if (newlyAppliedPromotions.length) {
      setShow(true);
      currentAppliedPromotionRef.current =
      newlyAppliedPromotions[newlyAppliedPromotions.length - 1];
    }
  }, [newlyAppliedPromotions]);

  const handleClose = () => {
    setShow(false);
    userAcknowledgedPromotionsHandler(newlyAppliedPromotions);
  };

  const currentAppliedPromotion = useMemo(() => {
    return newlyAppliedPromotions[length - 1];
  }, [newlyAppliedPromotions]);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="promotion-applied-modal centered"
    >
      <div className="popup-overlay" />
      <div className="popup-banner">
        <img src={promotionAppliedIcon} className="icon" />
        <h2 className="popup-heading">
          <FormattedMessage
            id="promotion_unlocked"
            defaultMessage="Promotion Unlocked!"
          />
        </h2>
        <div className="description-container">
          <p className="popup-desc">
            <span>{currentAppliedPromotion?.name}</span>
          </p>
        </div>
        <div>
        <button className="ok-button" onClick={handleClose}>
          <FormattedMessage id="button.ok" defaultMessage="OK" />
        </button>
        </div>
        
      </div>
    </Modal>
  );
};

export default AppliedPromotionsModalMobile;
