// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
// Components
import InlineLoader from "../../components/inlineLoader";
import LocaleDropdown from "../localeDropdown";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Coin from "../../images/coin.png";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { loyaltyOperations } from "../../state/features/loyalty";
import { loaderOperations } from "../../state/features/loader";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import { promotionsOperations } from "../../state/features/promotions";

interface ISideMenuProps {
  company: any;
  user: any;
  showAreaSelect: boolean;
  loyaltyEnabled: boolean;
  onClose: any;
  signOut: any;
  fetchLoyalty: any;
  userLoyaltyPoints: any;
  showLoader: any;
  availablePromotions: any[];
  togglePromotionModal(): void;
}
interface ISideMenuState {
  loyaltyLoading: boolean;
}

class SideMenu extends Component<ISideMenuProps, ISideMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loyaltyLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.user.id) {
      if (
        ["/m", "/m/checkout", "/m/my-profile", "/m/trackorder"]
          .includes(location.pathname) && this.props.loyaltyEnabled
      ) {
        this.props.fetchLoyalty();
      }
      this.setState({ loyaltyLoading: false });
    }
  }

  handleClose = (redirectUrl: string) => {
    if (location.pathname != redirectUrl) {
      if(redirectUrl == '/show-search'){
        setTimeout(function(){
          document.getElementById("menu-search-wrapper").classList.add("searching");
          document.getElementById("search-input").focus();
        },100);
      }else{
        this.props.showLoader();
      }
    }
    if (redirectUrl == "/m/login") {
      localStorage.removeItem("postRegistrationPage");
    }
    this.props.onClose();
  };

  goTo = (url: string) => {
    this.props.showLoader();
    window.location.href = url;
  };

  onLogoutClick = () => {
    this.props.signOut().then((response: any) => {
      if (!response.error) {
        window.location.href = response.payload.data.redirect_url.replace(
          "/",
          "/m"
        );
      }
    });
  };

  showPromotionModal = () => {
    this.props.togglePromotionModal();
  }

  render() {
    const { company, showAreaSelect, userLoyaltyPoints } = this.props;
    return (
      <div id="side" className="side-menu open">
        <div className="header">
          <div className="menu-icon-wrapper">
            <FontAwesomeIcon
              className="menu-icon"
              onClick={this.props.onClose}
              icon={faTimes}
            />
          </div>
          {company.locale_data?.locales.length > 1 ? (
            <LocaleDropdown />
          ) : false}
        </div>
        <div className="menu-list">
          <Link
            to="/m"
            onClick={() => this.handleClose("/m")}
            className="menu-link"
          >
            <FormattedMessage
              id="global.home"
              defaultMessage="Home"
            />
          </Link>
          {location.pathname === "/m" && !showAreaSelect ? (
            <Link
              to="/m"
              onClick={() => this.handleClose("/show-search")}
              className="menu-link"
            >
              <FormattedMessage
                id="global.search"
                defaultMessage="Search"
              />
            </Link>
          ) : null}
          {this.props.user.id? (
            <React.Fragment>
              {this.props.availablePromotions.length ? (
                <div onClick={this.showPromotionModal} className="menu-link">
                  <FormattedMessage
                    id="sidemenu.promotions"
                    defaultMessage="Promotions"
                  />
                </div>
              ) : null}
              <Link
                to="/m/past-orders"
                onClick={() => this.handleClose("/m/past-orders")}
                className="menu-link"
              >
                <FormattedMessage
                  id="sidemenu.my_orders"
                  defaultMessage="My Orders"
                />
              </Link>
              <Link
                to="/m/my-profile"
                onClick={() => this.handleClose("/m/my-profile")}
                className="menu-link"
              >
                <FormattedMessage
                  id="sidemenu.my_account"
                  defaultMessage="My Account"
                />
              </Link>
              <div onClick={this.onLogoutClick} className="menu-link">
                <FormattedMessage
                  id="sidemenu.logout"
                  defaultMessage="Logout"
                />
              </div>
            </React.Fragment>
          ) : (
            <Link
              to="/m/login"
              onClick={() => this.handleClose("/m/login")}
              className="menu-link"
            >
              <FormattedMessage
                id="sidemenu.login"
                defaultMessage="Login"
              />
            </Link>
          )}
        </div>
        {this.props.loyaltyEnabled && this.props.user.id ? (
          <div className="loyalty-widget">
            {this.state.loyaltyLoading ? (
              <InlineLoader />
            ) : (
              <div
                className="loyalty-info"
                style={{ backgroundImage: `url(${Coin})` }}
                onClick={() => this.goTo("/m/past-orders")}
              >
                <div className="loyalty">
                  <p className="label">You currently have</p>
                  <p className="points">
                    {formatDecimal(userLoyaltyPoints)} Loyalty{" "}
                    {company.currency}
                  </p>
                </div>
                <button className="details">
                  <FormattedMessage
                    id="global.uppercase.details"
                    defaultMessage="DETAILS"
                  />
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let user = state.user || {};
  let company = state.company;
  let userLoyaltyPoints = state.loyalty.customer_loyalty || 0;
  let orderType = state.session.order_type || 0;
  let showAreaSelect = state.session.showAreaSelect;
  let availablePromotions = state.promotions.availablePromotions;

  return {
    company,
    user,
    userLoyaltyPoints,
    showAreaSelect,
    availablePromotions,
  };
};

const mapDispatchToProps = {
  signOut: userOperations.signOut,
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
  showLoader: loaderOperations.showLoader,
  togglePromotionModal: promotionsOperations.toggleShowPromotionModal,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SideMenu));
