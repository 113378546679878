import actions from "./actions";

const verifyPayment = actions.verifyPayment;
const buildCCAvenuePaymentData = actions.buildCCAvenuePaymentData;
const fetchMyFatoorahPaymentModes = actions.fetchMyFatoorahPaymentModes;
const createGeideaTempOrder = actions.createGeideaTempOrder;
const buildQpayPaymentData = actions.buildQpayPaymentData;
const buildMastercardSession = actions.buildMastercardSession;
const initateMPGSCardVerification = actions.initateMPGSCardVerification;
const submitMPGSCardFormData = actions.submitMPGSCardFormData;
const validateMPGSApplePayMerchant = actions.validateMPGSApplePayMerchant;
const makeMPGSApplePayPayment = actions.makeMPGSApplePayPayment;
const submitMPGSGooglePayTokenData = actions.submitMPGSGooglePayTokenData;
const userCancelledMPGSApplePayPayment = actions.userCancelledMPGSApplePayPayment;
const authenticateMPGSUser = actions.authenticateMPGSUser;
const verifyMPGSGooglePayCard = actions.verifyMPGSGooglePayCard;
const verifyMPGSGooglePayUser = actions.verifyMPGSGooglePayUser;
const updateMPGSPaymentStatus = actions.updateMPGSPaymentStatus;
const buildIPay88PaymentData = actions.buildIPay88PaymentData;
const buildFiservPaymentData = actions.buildFiservPaymentData;
const omisePlaceOrder = actions.omisePlaceOrder;
const buildAdyenPaymentMethods = actions.buildAdyenPaymentMethods;
const createAdyenPayment = actions.createAdyenPayment
const initiateStripeConnectTransaction = actions.initiateStripeConnectTransaction;
const fetchStripeConnectPaymentIntent = actions.fetchStripeConnectPaymentIntent;
const buildGenericPaymentsPayload = actions.buildGenericPaymentsPayload;
const checkGenericPaymentStatus = actions.checkGenericPaymentStatus;

export default {
  verifyPayment,
  buildCCAvenuePaymentData,
  fetchMyFatoorahPaymentModes,
  createGeideaTempOrder,
  buildQpayPaymentData,
  buildIPay88PaymentData,
  buildFiservPaymentData,
  omisePlaceOrder,
  buildAdyenPaymentMethods,
  createAdyenPayment,
  initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent,
  buildMastercardSession,
  initateMPGSCardVerification,
  submitMPGSCardFormData,
  validateMPGSApplePayMerchant,
  makeMPGSApplePayPayment,
  submitMPGSGooglePayTokenData,
  userCancelledMPGSApplePayPayment,
  authenticateMPGSUser,
  verifyMPGSGooglePayCard,
  verifyMPGSGooglePayUser,
  updateMPGSPaymentStatus,
  buildGenericPaymentsPayload,
  checkGenericPaymentStatus
};
