import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

interface PhoneNumberFieldProps {
  phoneNumber: string | undefined;
}

const PhoneNumberFieldMobile: React.FC<PhoneNumberFieldProps> = ({ phoneNumber = '' }) => {
  return (
    <div className="field">
      <label>
        <FormattedMessage id="my_account.mobile_number" defaultMessage="Mobile Number:" />
      </label>
      <input
        type="tel"
        value={phoneNumber || ''}
        disabled
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  phoneNumber: state.user.phone_number,
});

export default connect(mapStateToProps)(PhoneNumberFieldMobile);
