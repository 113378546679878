import * as types from "./types";

const setUser = (user: any) => ({
  type: types.SET_USER,
  payload: user,
});

const updateProfile = (name: string) => ({
  type: types.UPDATE_PROFILE,
  payload: {
    request: {
      method: "put",
      url: `/users/profiles/${name}`,
    }
  }
});

const fetchOrderInProgress = () => ({
  type: types.FETCH_ORDER_IN_PROGRESS,
  payload: {
    request: {
      method: "get",
      url: "/users/orders/in_progress"
    }
  }
});

const signOut = () => ({
  type: types.SIGN_OUT,
  payload: {
    request: {
      method: "delete",
      url: "/users/sign_out",
    }
  }
});

const verifyUser = (userData: any, csrfToken: string, captchaToken: string) => ({
  type: types.VERIFY_USER,
  payload: {
    request: {
      method: "post",
      url: "/users/verifications",
      data: userData,
      headers: {
        "X-CSRF-TOKEN": csrfToken,
        "CAPTCHA-TOKEN": captchaToken,
      }
    }
  }
});

const signUp = (userData: any, csrfToken: string) => ({
  type: types.SIGN_UP,
  payload: {
    request: {
      method: "post",
      url: "/users",
      data: {
        user: userData
      },
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    }
  }
});

const signIn = (userData: any, csrfToken: string) => ({
  type: types.SIGN_IN,
  payload: {
    request: {
      method: "post",
      url: "/users/sign_in",
      data: {
        user: userData
      },
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    }
  }
});

const sendOtp = (csrfToken: string) => ({
  type: types.SEND_OTP,
  payload: {
    request: {
      method: "post",
      url: "/users/otps",
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    }
  }
});

const sendOtpViaEmail = (payload: any, csrfToken: string) => ({
  type: types.SEND_OTP,
  payload: {
    request: {
      method: "post",
      url: "/users/otps",
      data: payload,
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    },
  },
});

const resendOtp = (csrfToken: string) => ({
  type: types.RESEND_OTP,
  payload: {
    request: {
      method: "post",
      url: "/users/otps/resend",
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    }
  }
});

const resendOtpViaEmail = (payload: any, csrfToken: string) => ({
  type: types.RESEND_OTP,
  payload: {
    request: {
      method: "post",
      url: "/users/otps/resend",
      data: payload,
      headers: {
        "X-CSRF-TOKEN": csrfToken
      }
    },
  },
});

const resetEmail = (
  userData: { email: string; otp: string },
  csrfToken: string
) => ({
  type: types.RESET_EMAIL,
  payload: {
    request: {
      method: "PATCH",
      url: "users/profiles/update_email",
      data: userData,
      headers: {
        "X-CSRF-TOKEN": csrfToken,
      }
    }
  }
});


export default {
  setUser,
  updateProfile,
  fetchOrderInProgress,
  signOut,
  verifyUser,
  signUp,
  signIn,
  sendOtp,
  resendOtp,
  sendOtpViaEmail,
  resendOtpViaEmail,
  resetEmail,
};
