import actions from "./actions";

const updateOrder = actions.updateOrder;
const placeOrder = actions.placeOrder;
const markAsCustomerArrived = actions.markAsCustomerArrived;

export default {
  updateOrder,
  placeOrder,
  markAsCustomerArrived,
};
