import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneNumberField from './phoneNumberField';
import EmailField from './emailField';


const PersonalDetails = () => {

  return (
    <div className="personal-details">
      <p className="section-heading">
        <FormattedMessage
          id="my_account.personal_details"
          defaultMessage="Personal Details"
        />
      </p>
      <PhoneNumberField />
      <EmailField
      />
    </div>
  );
};

export default PersonalDetails;
