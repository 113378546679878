import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import { RootState } from "../../state/store";
// Redux Operations
import { riderTipOperations } from "../../state/features/riderTip";

interface ITipsWidgetMobileProps {
  addRiderTip: (tipAmount: number) => void;
  removeRiderTip: () => void;
}

const TipsWidgetMobile = (props: ITipsWidgetMobileProps) => {
  const [tipValues, setTipValues] = useState([] as any);
  const [selectedTip, setSelectedTip] = useState({} as any);
  const [customTipValue, setCustomTipValue] = useState("" as any);
  const [showCustomTipContainer, setShowCustomTipContainer] = useState(false);

  const { currency } = useSelector((state: RootState) => state.company);
  const { subTotal } = useSelector((state: RootState) => state.cart);
  const { tips, riderTip } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (Object.keys(tips).length > 0) {
      let tipsArray = tips.values.map((tip: any, index: number) => {  
        return { type: tips.type, value: tip };
      })
      setTipValues(tipsArray);
    }
  }, [tips]);

  useEffect(() => {
    if(!riderTip) {
      setSelectedTip({});
      setShowCustomTipContainer(false);
    }
  }, [riderTip]);

  useEffect(() => {
    if (Object.keys(selectedTip).length > 0) {
      if (selectedTip.type === "percentage") {
        props.addRiderTip(formatDecimal(selectedTip.value / 100 * subTotal));
      } else {
        props.addRiderTip(formatDecimal(selectedTip.value));
      } 
    }
  },[selectedTip]);

  const setCustomTip = (e: any) => {
    let regExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (!e.target.value) {
      setCustomTipValue("");
      return;
    } else if (!regExp.test(e.target.value)) {
      return;
    }
    setCustomTipValue(e.target.value);
  };

  const addCustomTip = () => {
    if (customTipValue) {
      let tipValue = customTipValue;
      setSelectedTip({
        type: "value",
        value: parseFloat(formatDecimal(tipValue)),
      });
      props.addRiderTip(formatDecimal(customTipValue));
    }
  }

  const removeCustomTip = () => {
    setCustomTipValue("");
    setSelectedTip({});
    props.removeRiderTip();
    setShowCustomTipContainer(false);
  }

  const toggleTip = (newTip: any) => {
    if (selectedTip == newTip) {
      setSelectedTip({});
      props.removeRiderTip();
    } else {
      setSelectedTip(newTip);
    }
  }

  const renderTipsTiles = (tip: any) => {
    if (tip.type === "percentage") {
      return (
        <div>
          <p>{tip.value + "%"}</p>
          <p>
            {formatDecimal((tip.value / 100) * formatDecimal(subTotal))}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p></p>
          <p>
            {tip.value + ' ' + currency}
          </p>
        </div>
      );
    }
  }

  return (
    <div className="tip-section-mobile">
      <div className="section-heading mt-1 px-1 pt-2 pb-2">Leave a tip</div>
      {showCustomTipContainer ? (
        <div className="custom-tip-container">
          <p className="custom-tip-title">Enter Custom Amount</p>
          <div className="input-section-wrapper">
            <p className="currency">{currency}</p>
            <input
              type="text"
              value={customTipValue}
              className="custom-tip-input"
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCustomTip(e)
              }
            />
            <button className="btn-submit-tip" onClick={addCustomTip}>
              Apply
            </button>
          </div>
          <p className="remove-tip-label" onClick={removeCustomTip}>
            Remove Tip
          </p>
        </div>
      ) : (
        <div className="tip-container">
          <div className="tip-tiles-container">
            {tipValues.map((tip: any, index: number) => {
              return (
                <div
                  className={
                    selectedTip === tip ? "tip-tile selected" : "tip-tile"
                  }
                  key={index}
                  onClick={() => toggleTip(tip)}>
                  {renderTipsTiles(tip)}
                </div>
              );
            })}
          </div>
          <p
            className="custom-tip-label"
            onClick={() => setShowCustomTipContainer(true)}>
            Enter custom tip amount
          </p>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  addRiderTip: riderTipOperations.addRiderTip,
  removeRiderTip: riderTipOperations.removeRiderTip,
};

export default connect(null, mapDispatchToProps)(TipsWidgetMobile);