// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import ExpandedDeliveryAddressMapWidget from "../expandedDeliveryAddressMapWidget";
import MinimisedDeliveryAddressMapWidget from "../minimisedDeliveryAddressMapWidget";
// Redux Operations
import { addressesOperations } from "../../state/features/addresses";
import { errorOperations } from "../../state/features/error";

interface ISavedAddressWidgetMobileProps {
  address: any;
  addressConfiguration: any;
  reloadAddresses: any;
  updateAddress: any;
  deleteAddress: any;
  updateError: any;
}

interface ISavedAddressWidgetMobileState {
  addressConfiguration: any;
  showEdit: boolean;
  updatedAddress: any;
  showExpanded: boolean;
  mapApiKey: string;
  addressMarked: boolean;
}

class SavedAddressWidgetMobile extends Component<
  ISavedAddressWidgetMobileProps,
  ISavedAddressWidgetMobileState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      addressConfiguration: {
        "1": props.intl.formatMessage({ id: "address.configuration.building_villa", defaultMessage: "Building / Villa" }),
        "2": props.intl.formatMessage({ id: "address.configuration.street", defaultMessage: "Street" }),
        "3": props.intl.formatMessage({ id: "address.configuration.flat_number", defaultMessage: "Flat Number" }),
        "4": props.intl.formatMessage({ id: "address.configuration.nearest_landmark", defaultMessage: "Nearest Landmark" }),
      },
      showEdit: false,
      updatedAddress: {
        latitude: 0,
        longitude: 0,
        delivery_addresses: [
          { id: "1", value: "" },
          { id: "2", value: "" },
          { id: "3", value: "" },
          { id: "4", value: "" },
        ],
      },
      showExpanded: false,
      mapApiKey: "",
      addressMarked: false,
    };
  }

  componentDidMount() {
    this.findAddressConfiguration();
    this.findAddress();
    this.findMapApiKey();
  }

  findAddressConfiguration = () => {
    if (this.props.addressConfiguration) {
      this.setState({
        addressConfiguration: this.props.addressConfiguration,
      });
    }
  };

  findAddress = () => {
    this.setState({ updatedAddress: Object.assign({}, this.props.address) });
  };

  findMapApiKey = () => {
    let element = document.getElementById("root") as any;
    this.setState({ mapApiKey: element?.dataset.googleMapKey });
  };

  addressLineValue = (id: string) => {
    let address = this.state.updatedAddress.delivery_addresses.find(
      (line: any) => {
        return line.id == id;
      }
    );
    return address.value;
  };

  truncateString = (text: string | null, length: number): string => {
    if (!text) return '';
    return text.length > length ? `${text.slice(0, length - 1)}...` : text;
  };

  onAddressEditClick = () => {
    this.setState({ showEdit: true });
  };

  onAddressDeleteClick = () => {
    this.props
      .deleteAddress(this.state.updatedAddress)
      .then((response: any) => {
        if (!response.error) {
          this.props.reloadAddresses();
        }
      });
  };

  handleEditAddressCancel = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      updatedAddress: Object.assign({}, this.props.address),
      showEdit: false,
      addressMarked: false,
    });
  };

  handleUpdateAddress = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props
      .updateAddress(this.state.updatedAddress)
      .then((response: any) => {
        if (!response.error) {
          this.props.reloadAddresses();
        } else {
          this.setState({ showEdit: false });
        }
      });
  };

  changeAreaInMap = (addressLine: any, latitude: any, longitude: any) => {
    let address = this.state.updatedAddress;
    address.latitude = latitude;
    address.longitude = longitude;
    this.setState({ updatedAddress: address }, () =>
      this.handleAddressLineChange(addressLine, "2")
    );
  };

  handleAddressLineChange = (addressLine: string, id: string) => {
    let updatedAddress = this.state.updatedAddress;
    let addressLines = updatedAddress.delivery_addresses.filter((line: any) => {
      return line.id != id;
    });
    addressLine = {
      id: id,
      value: addressLine,
    };
    addressLines.push(addressLine);
    updatedAddress.delivery_addresses = addressLines;
    this.setState({ updatedAddress });
  };

  minimiseMap = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ showExpanded: false });
  };

  expandMap = () => {
    this.setState({ showExpanded: true });
  };

  toggleAddressMarked = (addressMarked: boolean) => {
    this.setState({ addressMarked });
  }

  render() {
    const {
      showEdit,
      addressConfiguration,
      showExpanded,
      mapApiKey,
      addressMarked,
      updatedAddress,
    } = this.state;
    const addressLines = updatedAddress.delivery_addresses;
    const area = addressLines[1].value ? `, ${addressLines[1].value}` : "";
    const landMark = addressLines[3].value ? `, ${addressLines[3].value}` : "";
    return (
      <div className="row" key={updatedAddress.address_id}>
        <div className="section nested col-lg-12 col-md-9 col-sm-9">
          {showEdit && (
            <div className="editAddressWrapper">
              <div className="my-profile-section-header">
                <h2 className="header">
                  <FormattedMessage
                    id="address.edit_address"
                    defaultMessage="Edit Address"
                  />
                </h2>
              </div>
              <form
                onSubmit={this.handleUpdateAddress}
                onReset={this.handleEditAddressCancel}
              >
                <div className="form-group mb-4 mt-4">
                  <label className="input-label bold mb-2">
                    <FormattedMessage
                      id="address.pin_exact_location"
                      defaultMessage="Pin Exact Location"
                    />
                  </label>
                  <div
                    className={
                      showExpanded
                        ? "delivery-area-map full"
                        : "delivery-area-map"
                    }
                  >
                    {showExpanded ? (
                      <ExpandedDeliveryAddressMapWidget
                        lat={parseFloat(updatedAddress.latitude) || 0}
                        lng={parseFloat(updatedAddress.longitude) || 0}
                        onAreaChange={(name: any, lat: any, lng: any) => {
                          this.changeAreaInMap(name, lat, lng);
                        }}
                        minimiseMap={(e: React.MouseEvent<HTMLButtonElement>) =>
                          this.minimiseMap(e)
                        }
                        mapApiKey={mapApiKey}
                        toggleAddressMarked={(addressMarked: boolean) => {
                          this.toggleAddressMarked(addressMarked)
                        }}
                        source="saved-address"
                      />
                    ) : (
                      <div
                        className={
                          showExpanded ? "delivery-area-map full" : "delivery-area-map"
                        }
                      >
                        <div
                          className={
                            addressMarked
                            ? "initial-message hide"
                            : "initial-message show"
                          }
                          onClick={this.expandMap}
                        >
                          <FormattedMessage
                            id="address.tap_to_pin"
                            defaultMessage="Tap here to pin your exact location."
                          />
                        </div>
                        {!showExpanded && mapApiKey ? (
                          <MinimisedDeliveryAddressMapWidget
                            lat={parseFloat(updatedAddress.latitude) || 0}
                            lng={parseFloat(updatedAddress.longitude) || 0}
                            onAreaChange={(name: any, lat: any, lng: any) => {
                              this.changeAreaInMap(name, lat, lng);
                            }}
                            expandMap={() => this.expandMap()}
                            mapApiKey={mapApiKey}
                            addressMarked ={addressMarked}
                          />
                        ) : null}
                      </div>
                    )}
                    {showExpanded && (
                      <div className="back-checkout my-account">
                        <button
                          className="btn btn-primary px-4 py-3 w-100"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                            this.minimiseMap(e)
                          }
                        >
                          <FormattedMessage
                            id="googlemap.use_this_location"
                            defaultMessage="Use This Location"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="input-label bold mb-0">
                    {addressConfiguration["2"]}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    name="address_2"
                    type="text"
                    required
                    className="form-control custom-input mt-2"
                    value={this.addressLineValue("2")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleAddressLineChange(e.target.value, "2")
                    }
                  ></input>
                </div>
                <div className="form-group mb-4">
                  <label className="input-label bold mb-0">
                    {addressConfiguration["1"]}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    name="address_1"
                    type="text"
                    required
                    className="form-control custom-input mt-2"
                    value={this.addressLineValue("1")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleAddressLineChange(e.target.value, "1")
                    }
                  ></input>
                </div>
                <div className="form-group mb-4">
                  <label className="input-label bold mb-0">
                    {addressConfiguration["3"]}{" "}
                    <span>(
                      <FormattedMessage
                        id="address.optional"
                        defaultMessage="Optional"
                      />
                    )</span>
                  </label>
                  <input
                    name="address_3"
                    type="text"
                    className="form-control custom-input mt-2"
                    value={this.addressLineValue("3")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleAddressLineChange(e.target.value, "3")
                    }
                  ></input>
                </div>
                <div className="form-group mb-4">
                  <label className="input-label bold mb-0">
                    {addressConfiguration["4"]}{" "}
                    <span>(
                      <FormattedMessage
                        id="address.optional"
                        defaultMessage="Optional"
                      />
                    )</span>
                  </label>
                  <input
                    name="address_4"
                    type="text"
                    className="form-control custom-input mt-2"
                    value={this.addressLineValue("4")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleAddressLineChange(e.target.value, "4")
                    }
                  ></input>
                </div>
                <div className="actions">
                  <button type="reset" className="btn btn-secondary px-4 mr-2">
                    <FormattedMessage
                      id="global.cancel"
                      defaultMessage="Cancel"
                    />
                  </button>
                  <button type="submit" className="btn btn-primary px-4 ">
                    <FormattedMessage
                      id="global.save"
                      defaultMessage="Save"
                    />
                  </button>
                </div>
              </form>
            </div>
          )}
          <div className="address">
            <div className="area-details">
              <p className="area">
                {this.truncateString(addressLines[1].value, 40)}
              </p>
              {addressLines[2].value ? (
                <p className="city">
                  {this.truncateString(addressLines[2].value + landMark, 40)}
                </p>
              ) : (
                false
              )}
            </div>
            {!showEdit && (
              <div className="actions">
                <button
                  className="small-button btn btn-sm btn-secondary"
                  onClick={this.onAddressEditClick}
                >
                  <FormattedMessage
                    id="global.edit"
                    defaultMessage="Edit"
                  />
                </button>
                <button
                  className="small-button btn btn-sm btn-secondary"
                  onClick={this.onAddressDeleteClick}
                >
                  <FormattedMessage
                    id="global.delete"
                    defaultMessage="Delete"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let addressConfiguration = state.company.address_configuration;
  return { addressConfiguration };
};

const mapDispatchToProps = {
  updateAddress: addressesOperations.updateAddress,
  deleteAddress: addressesOperations.deleteAddress,
  updateError: errorOperations.updateError,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SavedAddressWidgetMobile));
