import * as types from "./types";

const riderTipReducer = ( state = null, action: any ) => {
  switch( action.type ) {
    case types.ADD_RIDER_TIP:
      return action.payload;
    case types.REMOVE_RIDER_TIP:
      return null;

    default: return state;
  }
};

const reducer = riderTipReducer;

export default reducer;
