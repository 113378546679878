// Packages
import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useContext
} from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
// Components
import DialingCodeSelector from "../../mobileComponents/dialingCodeSelector";
import MobileVerification from "../../mobileScreens/mobileVerificationScreen";
import CheckboxReCaptchaEnterprise from "../../mobileComponents/captchas/googleCaptchas/checkboxReCaptchaEnterprise";
// Icons, Images etc.
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMAIL_REGEX } from "../../helpers/constants";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { pageOperations } from "../../state/features/page";
import { loaderOperations } from "../../state/features/loader";
// Contexts
import { EnvContext } from "../../components/envContext";

interface IUserLoginProps {
  user: any;
  company?: any;
  captchaEnabled: boolean;
  fetchSignInConfigurations: any;
  showLoader: any;
  verifyUser: (data: any, csrfToken: string, captchaToken: string) => void;
  signUp: (data: any, token: string) => void;
}

function UserLogin(props: IUserLoginProps) {
  const [userPayload, setUserPayload] = useState({} as any);
  const [dialingCode, setDialingCode] = useState("");
  const [postRegistrationPage, setPostRegistrationPage] = useState("");
  const [pageType, setPageType] = useState("sign-in");
  const [signInError, setSignInError] = useState(null as any);
  const [redirectURL, setRedirectURL] = useState("");
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaWidgetID, setCaptchaWidgetID] = useState(null);

  const emailRef = useRef() as MutableRefObject<HTMLInputElement>;

  const { googleRecaptchaSiteKey } = useContext(EnvContext);

  useEffect(() => {
    props.fetchSignInConfigurations().then((response: any) => {
      if (!response.error) {
        if (response.payload.data.user?.id) {
          props.showLoader();
          setRedirectURL("/m");
        }
        if (localStorage.postRegistrationPage) {
          setPostRegistrationPage(localStorage.postRegistrationPage);
        }
      }
    });
  }, []);

  useEffect(() => {
    // Set Email from Localstorage if user email does not exist
    if (showEmailLogin && !userPayload.email && emailRef.current && localStorage.userEmail) {
      emailRef.current.value = localStorage.getItem("userEmail") as string;
      setUserPayload({
        ...userPayload,
        [emailRef.current.name]: emailRef.current.value,
      });
    }
  }, [showEmailLogin]);

  useEffect(() => {
    if (props.captchaEnabled && !captchaToken) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [props.captchaEnabled, captchaToken]);

  const validatePhoneNumber = (number: any) => {
    let regex = /^[0-9]{6,15}$/;
    return regex.test(number);
  };

  const validateEmail = (email: string) => {
    return EMAIL_REGEX.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSignInError(null);
    setUserPayload({ ...userPayload, [e.target.name]: e.target.value });
  };

  const submitSignIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let payload = userPayload as any;
    let isPhoneNumberValid = validatePhoneNumber(payload.phone_number);
    payload.company_id = props.company.id;
    payload.dialing_code = dialingCode;

    if (props.captchaEnabled) {
      // Reset Captcha Widget
      grecaptcha.enterprise.reset(captchaWidgetID);
    }

    if (isPhoneNumberValid) {
      const csrfToken = document.querySelector('[name=csrf-token]').content;
      props.verifyUser(payload, csrfToken, captchaToken).then((response: any) => {
        if (!response.error) {
          const email = response.payload.data.user.email;
          if (email) {
            setUserPayload({
              ...userPayload,
              email: email,
            });
          }
          if (props.company?.sms_disabled) {
            setShowEmailLogin(true);
            if (email) {
              setPageType("verification");
            }
          } else {
            setPageType("verification");
          }
        } else if (response.error.response.status == 404) {
          props.signUp(payload, csrfToken).then((response: any) => {
            if (!response.error) {
              const email = response.payload.data.email;
              if (email) {
                setUserPayload({
                  ...userPayload,
                  email: email,
                });
              }
              if (props.company?.sms_disabled) {
                setShowEmailLogin(true);
                if (email) {
                  setPageType("verification");
                }
              } else {
                setPageType("verification");
              }
            } else {
              setSignInError(response.error.response.data);
            }
          });
        } else {
          setSignInError(response.error.response.data);
        }
      });
    } else {
      setSignInError({ phone_number: "Enter a valid phone number" });
    }
  };

  const submitSignInViaEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let isEmailValid = validateEmail(userPayload?.email);
    if (isEmailValid) {
      localStorage.setItem("userEmail", userPayload?.email);
      setPageType("verification");
    } else {
      setSignInError({ email: "Please enter a valid email" });
    }
  };

  const changeDialingCode = (dialingCode: any) => {
    setSignInError(null);
    setDialingCode(dialingCode);
  };

  const switchToEmailOtp = (status: boolean) => {
    setCaptchaToken("");
    setShowEmailLogin(status);
  }

  const onCaptchaTokenChange = (token: string) => {
    setCaptchaToken(token);
  }

  const onCaptchaErrored = () => {
    setCaptchaToken("");
  }

  const onCaptchaExpired = () => {
    setCaptchaToken("");
  }

  const onCaptchaWidgetIDChange = (widgetID: number) => {
    setCaptchaWidgetID(widgetID);
  }

  const signInPage = (
    <React.Fragment>
      <div className="user-login px-3 mt-5 pt-4">
        {!showEmailLogin ? (
          <>
            <h1 className="header mb-1">
              {postRegistrationPage == "/m/checkout" ? (
                <FormattedMessage
                  id="registration.continue_to_checkout"
                  defaultMessage="Continue To Checkout"
                />
              ) : (
                <FormattedMessage
                  id="registration.sign_in_to_your_account"
                  defaultMessage="Sign In To Your Account"
                />
              )}
            </h1>
            <h3 className="mb-4 text-center">
              {postRegistrationPage == "/m/checkout" ? (
                <FormattedMessage
                  id="registration.please_enter_your_mobile_number"
                  defaultMessage="Please enter your mobile number"
                />
              ) : (
                <FormattedMessage
                  id="registration.sign_in_with_your_mobile_number"
                  defaultMessage="Sign in with your mobile number"
                />
              )}
            </h3>
            <div>
              <form onSubmit={submitSignIn}>
                <div className="form-group">
                  <div className="input-group" dir="ltr">
                    <div className="input-group-prepend">
                      {props.company.country_code ? (
                        <DialingCodeSelector
                          dialingCode={dialingCode}
                          onChange={(dialingCode: any) => {
                            changeDialingCode(dialingCode);
                          }}
                          countryCode={props.company.country_code}
                        />
                      ) : null}
                    </div>
                    <FormattedMessage
                      id="registration.mobile_number"
                      defaultMessage="Mobile number"
                    >
                      {(placeholder: any) => (
                        <input
                          name="phone_number"
                          type="number"
                          placeholder={placeholder}
                          required
                          autoFocus
                          className="form-control custom-input"
                          onChange={handleInputChange}
                          pattern="[0-9]*"
                        ></input>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
                {signInError ? (
                  <div className="input-label text-center text-danger">
                    {signInError["phone_number"] ? (
                      <FormattedMessage
                        id="registration.phone_number.validation"
                        defaultMessage="Enter a valid phone number"
                      />
                    ) : (
                      <FormattedMessage
                        id="error.oops_something_went_wrong"
                        defaultMessage="Oops! Something went wrong."
                      />
                    )}
                  </div>
                ) : null}
                <div className="mt-4 text-center">
                  <FormattedMessage
                    id="registration.terms_of_service"
                    defaultMessage="By signing-in to your account, you agree to {breakTag}our <buttonTag>Terms of Service</buttonTag>."
                    values={{
                      breakTag: <br />,
                      buttonTag: (text: string) => (
                        <a href="/m/terms-of-service">{text}</a>
                      ),
                    }}
                  />
                </div>
                {props.captchaEnabled && (
                  <div className="reCaptchaContainer">
                    <CheckboxReCaptchaEnterprise
                      onCaptchaTokenChange={onCaptchaTokenChange}
                      onCaptchaExpired={onCaptchaExpired}
                      onCaptchaErrored={onCaptchaErrored}
                      onCaptchaWidgetIDChange={onCaptchaWidgetIDChange}
                    />
                  </div>
                )}
                <button
                  className="button btn btn-primary w-100 py-2 mt-3"
                  type="submit"
                  disabled={submitButtonDisabled}
                >
                  <FormattedMessage id="global.next" defaultMessage="Next" />
                  <span className="ml-3">
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                  </span>
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <h1 className="header mb-1">
              <FormattedMessage
                id="registration.enter_email"
                defaultMessage="Enter Your Email Address"
              />
            </h1>
            <br />
            <div>
              <form onSubmit={submitSignInViaEmail}>
                <div className="form-group">
                  <FormattedMessage
                    id="registration.email.placeholder"
                    defaultMessage="example@email.com"
                  >
                    {(placeholder) => (
                      <input
                        type="text"
                        name="email"
                        ref={emailRef}
                        required
                        autoFocus
                        placeholder={placeholder.toString()}
                        className="email-input form-control"
                        onChange={handleInputChange}
                      />
                    )}
                  </FormattedMessage>
                </div>
                {signInError ? (
                  <div className="input-label text-center text-danger">
                    {signInError["email"] ? (
                      <FormattedMessage
                        id="registration.email.validation"
                        defaultMessage="Please enter a valid email address."
                      />
                    ) : null}
                  </div>
                ) : null}
                {props.captchaEnabled && (
                  <div className="reCaptchaContainer">
                    <CheckboxReCaptchaEnterprise
                      onCaptchaTokenChange={onCaptchaTokenChange}
                      onCaptchaExpired={onCaptchaExpired}
                      onCaptchaErrored={onCaptchaErrored}
                      onCaptchaWidgetIDChange={onCaptchaWidgetIDChange}
                    />
                  </div>
                )}
                <button
                  className="button btn btn-primary w-100 py-2 mt-3"
                  type="submit"
                  disabled={submitButtonDisabled}
                >
                  <FormattedMessage
                    id="global.continue"
                    defaultMessage="Continue"
                  />
                  <span className="ml-3">
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                  </span>
                </button>
              </form>
              {!props.company?.sms_disabled ? (
                <p
                  className="change-otp-mode-sms"
                  onClick={() => switchToEmailOtp(false)}
                >
                  <FormattedMessage
                    id="registration.otp.send_via_sms"
                    defaultMessage="Send via SMS"
                  />
                </p>
              ) : null}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <div className="login-screen">
      {redirectURL ? (
        <Redirect to={redirectURL} />
      ) : pageType == "verification" ? (
        <MobileVerification
          onClose={() => setPageType("sign-in")}
          postRegistrationPage={postRegistrationPage}
          setShowEmailOtp={switchToEmailOtp}
          showEmailOtp={showEmailLogin}
          userPayload={userPayload}
        />
      ) : (
        signInPage
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company || {};
  let captchaEnabled = company.configuration?.captcha_enabled || false;
  return {
    company,
    captchaEnabled,
  };
};

const mapDispatchToProps = {
  verifyUser: userOperations.verifyUser,
  signUp: userOperations.signUp,
  fetchSignInConfigurations: pageOperations.fetchSignInConfigurations,
  showLoader: loaderOperations.showLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
