import * as types from "./types";

const setCart = (cart: any) => ({
  type: types.SET_CART,
  payload: cart,
});

const validateCart = (payload: any) => ({
  type: types.VALIDATE_CART,
  payload: {
    request: {
      method: "post",
      url: `orders/validate`,
      data: {order_details: payload},
    }
  }
});

export default { setCart, validateCart };
