import {
  DISCOUNT_TYPE,
  PROMOTION_ACTIONS,
  PROMOTION_CONDITION_TYPES,
  PROMOTION_TYPES,
} from "../types/promotionTypes";
import { v4 as uuidv4 } from "uuid";

/**
 * Appends category details to a cart item based on its ID.
 * @param cartItem - The cart item to append category details to.
 * @param categories - An array of categories containing items.
 * @returns The updated cart item with category details.
 */
function appendCategoryDetails(cartItem: any, categories: any[]) {
  let newCartItem: any = null;
  categories.map((category) => {
    category.items.map((item: any) => {
      if (item.id === cartItem.id) {
        newCartItem = {
          ...cartItem,
          category_id: category.id,
          category_name: category.name,
        };
      } else if (item.items && item.items.length) {
        //grouped items
        item.items.map((subItem: any) => {
          if (subItem.id === cartItem.id) {
            newCartItem = {
              ...cartItem,
              category_id: category.id,
              category_name: category.name,
            };
          }
        });
      }
    });
  });
  return newCartItem;
}

/**
 * Duplicates cart items based on their count.
 * @param cartItems - An array of cart items.
 * @returns The updated array of cart items with duplicated items.
 */
function duplicateCartItems(cartItems: any[]) {
  const duplicatedCartItems: any[] = [];
  cartItems.forEach((cartItem) => {
    if (cartItem?.count > 1) {
      for (let i = 0; i < cartItem.count; i++) {
        const duplicatedItem = { ...cartItem };
        duplicatedItem.temp_uuid = uuidv4(); // Add UUID to the item
        duplicatedItem.count = 1;
        duplicatedItem.sub_total = cartItem.sub_total / cartItem.count;
        duplicatedCartItems.push(duplicatedItem);
      }
    } else {
      const item = { ...cartItem };
      item.temp_uuid = uuidv4(); // Add UUID to the item
      duplicatedCartItems.push(item);
    }
  });
  return duplicatedCartItems;
}

function remergeCart(cartItems: any[]) {
  const mergedCartItems: any[] = [];
  cartItems.forEach((cartItem) => {
    const existingItem = mergedCartItems.find(
      (item) => item.cart_id === cartItem.cart_id
    );
    if (existingItem) {
      existingItem.count += 1;
      existingItem.sub_total += cartItem.sub_total;
      delete existingItem.temp_uuid;
    } else {
      delete cartItem.temp_uuid;
      mergedCartItems.push(cartItem);
    }
  });
  return mergedCartItems;
}

/**
 * Finds cart subtotal
 * @param cartItems - An array of cart items.
 * @returns The updated subtotal of the cart items
 */
function findCartSubtotal(cartItems: any[]) {
  return cartItems.reduce((subtotal, item) => {
    return subtotal + item.sub_total;
  }, 0);
}

/**
 * Sorts promotions by priority
 * @param availablePromotions - An array of available promotions.
 * @returns sorted promotions by priority
 */
function sortPromotions(availablePromotions: any[]) {
  const sortedPromotions = availablePromotions.sort(
    (a, b) => a.priority - b.priority
  );
  return sortedPromotions;
}

function findCartAppliedPromotions(cart: any[], promotions: any[]) {
  const appliedPromotions: any[] = [];
  cart.forEach((cartItem) => {
    const promotion = promotions.find(
      (promotion: any) => promotion.id === cartItem.applied_promotion_id
    );
    if (promotion && !appliedPromotions.includes(promotion)) {
      appliedPromotions.push(promotion);
    }
  });
  return appliedPromotions;
}

//--------------------BASIC PROMO CALCULATIONS
function applyPercentageDiscount(cartItems: any[], promotion: any) {
  let itemTotal = 0;
  let maxDiscount = promotion.action.max_discount_value ?? 0;
  let discountValue = promotion.action.value;
  let totalDiscount = 0;
  return cartItems.map((item) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority
    ) {
      return item;
    }
    itemTotal = promotion.action.price_modifiers
      ? item.sub_total
      : item.unit_price;
    totalDiscount = item.sub_total - (itemTotal * discountValue) / 100;
    totalDiscount =
      maxDiscount > 0 && totalDiscount > maxDiscount
        ? maxDiscount
        : totalDiscount;
    return {
      ...item,
      sub_total: totalDiscount,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
}

function applyValueDiscount(cartItems: any[], promotion: any) {
  let maxDiscount = promotion.action.max_discount_value;
  let discountValue = promotion.action.value;
  let totalDiscount = 0;
  let itemTotal = 0;
  return cartItems.map((item) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority
    ) {
      return item;
    }
    itemTotal = promotion.action.price_modifiers
      ? item.sub_total
      : item.unit_price;
    totalDiscount = itemTotal - discountValue;
    totalDiscount =
      maxDiscount > 0 && totalDiscount > maxDiscount
        ? maxDiscount
        : totalDiscount;
    if (totalDiscount < 0) {
      totalDiscount = 0;
    }
    return {
      ...item,
      sub_total: totalDiscount,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
}

function freeItem(cartItems: any[], promotion: any) {
  const maxDiscountItems = promotion.action.quantity ?? cartItems.length;
  let itemTotal = 0;
  let totalDiscount = 0;
  let applicableItems = cartItems.slice(0, maxDiscountItems);
  let unapplicableItems = cartItems.slice(maxDiscountItems);
  let promotionAppliedItems = applicableItems.map((item, index) => {
    if (
      item.applied_promotion_id &&
      item.applied_promotion_priority <= promotion.priority &&
      index + 1 > maxDiscountItems
    ) {
      return item;
    }
    itemTotal = promotion.action.price_modifiers
      ? item.sub_total
      : item.unit_price;
    totalDiscount = item.sub_total - itemTotal;
    return {
      ...item,
      sub_total: totalDiscount,
      applied_promotion_id: promotion.id,
      applied_promotion_priority: promotion.priority,
    };
  });
  return [...promotionAppliedItems, ...unapplicableItems];
}
/**
 * Finds promotion applicable items in the cart based on the promotion's include and exclude criteria.
 * @param cartItems - An array of cart items.
 * @param promotionAppliableItems - An array of promotion applicable items.
 * @returns The array of applicable items for the promotion.
 */
function findPromotionApplicableItems(
  cartItems: any[],
  promotionAppliableItems: any[]
) {
  const applicableItems: any[] = [];
  if (promotionAppliableItems && promotionAppliableItems.length) {
    promotionAppliableItems.forEach((item) => {
      if (item.field === "item_id") {
        const items = cartItems.filter((cartItem) => cartItem.id == item.id);
        applicableItems.push(...items);
      } else {
        const items = cartItems.filter(
          (cartItem) => cartItem.category_id == item.id
        );
        applicableItems.push(...items);
      }
    });
  } else {
    return cartItems;
  }

  return applicableItems;
}

function findPromotionUnapplicableItems(
  cartItems: any[],
  applicableItems: any[]
) {
  return cartItems.filter((cartItem) => {
    return !applicableItems.includes(cartItem);
  });
}

function combineDiscountedAndNonApplicableItems(
  discountedItems: any[],
  nonapplicableItems: any[]
): any[] {
  return [...discountedItems, ...nonapplicableItems];
}

function applyDiscount(
  cart: any[],
  promotion: any,
  discountFunction: (items: any[], promotion: any) => any[]
): any[] {
  if (
    promotion.action.include.length === 0 &&
    promotion.action.exclude.length === 0
  ) {
    return discountFunction(cart, promotion);
  }

  if (
    promotion.action.include.length &&
    promotion.action.exclude.length === 0
  ) {
    const applicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.include
    );
    const nonapplicableItems = findPromotionUnapplicableItems(
      cart,
      applicableItems
    );
    const discountedApplicableItems = discountFunction(
      applicableItems,
      promotion
    );
    return combineDiscountedAndNonApplicableItems(
      discountedApplicableItems,
      nonapplicableItems
    );
  } else if (
    promotion.action.exclude.length &&
    promotion.action.include.length === 0
  ) {
    const nonapplicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.exclude
    );
    const applicableItems = findPromotionUnapplicableItems(
      cart,
      nonapplicableItems
    );
    const discountedNonapplicableItems = discountFunction(
      applicableItems,
      promotion
    );
    return combineDiscountedAndNonApplicableItems(
      discountedNonapplicableItems,
      nonapplicableItems
    );
  } else {
    let applicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.include
    );

    const unapplicableItems = findPromotionApplicableItems(
      cart,
      promotion.action.exclude
    );
    applicableItems = applicableItems.filter(
      (item) => !unapplicableItems.includes(item)
    );
    const discountedNonapplicableItems = discountFunction(
      applicableItems,
      promotion
    );
    const nonapplicableItems = findPromotionUnapplicableItems(
      cart,
      applicableItems
    );
    return combineDiscountedAndNonApplicableItems(
      discountedNonapplicableItems,
      nonapplicableItems
    );
  }
}

//check if basic promotion conditions are satisfied
function applyBasicPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotion: any[]
) {
  const isDiscountOnOrder =
    promotion.action.type === PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER ||
    promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER;
  if (isDiscountOnOrder) {
    //discount on entire order
    orderPromotion.push(promotion);
  } else if (promotion.action.type === PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT) {
    promotionAppliedCart = applyDiscount(
      promotionAppliedCart,
      promotion,
      applyPercentageDiscount
    );
  } else if (promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT) {
    promotionAppliedCart = applyDiscount(
      promotionAppliedCart,
      promotion,
      applyValueDiscount
    );
  }
  return { promotionAppliedCart, orderPromotion };
}

//-------------------ADVANCED PROMO CALCULATIONS
function applyAdvancedDiscount(
  applicableItems: any[],
  promotion: any,
  discountFunction: (items: any[], promotion: any) => any[]
): any[] {
  const discountedItems = discountFunction(applicableItems, promotion);
  return discountedItems;
}

/**Filters items that have no promotion applied or have a lower priority promotion applied.
 * @param applicableItems - An array of items that are applicable for the promotion.
 * @param newPromotion - The new promotion to be applied.
 * @returns Item on which promotions can be applied.
 */
function filterOutPromotionAppliedItems(
  applicableItems: any[],
  newPromotion: any
) {
  return applicableItems.filter(
    (item) =>
      !item.applied_promotion_id ||
      item.applied_promotion_priority > newPromotion.priority
  );
}

function discountedItemSubtotal(
  item: any,
  pricedModifer: boolean,
  discountAmount: number
) {
  //for fixed discount only
  const modifierPrice = item.sub_total - item.unit_price;
  return pricedModifer ? discountAmount : discountAmount + modifierPrice;
}

function applyFixedPricePromotion(cartItems: any[], promotion: any) {
  //fixed price promotion on items
  const promotionAction = promotion.action;
  const applicableItems = filterOutPromotionAppliedItems(
    cartItems,
    promotionAction
  );
  const unapplicableItems = cartItems.filter(
    (item) => !applicableItems.includes(item)
  );
  const fixedValue = parseFloat(promotionAction.value);
  const minQuantity = promotion.condition.min_qty;
  const maxQuantity = promotion.condition.max_qty;
  let promotionAppliedItems: any[] = [];
  const exactQuantity = minQuantity === maxQuantity ? minQuantity : 0;
  const applicableItemsCount = applicableItems.length;
  if (minQuantity > 0 && applicableItemsCount >= minQuantity) {
    if (exactQuantity > 0) {
      if (applicableItemsCount % exactQuantity === 0) {
        const factor = Math.floor(applicableItemsCount / exactQuantity);
        const itemSubTotal = (fixedValue * factor) / applicableItems.length;
        promotionAppliedItems = applicableItems.map((item) => {
          return {
            ...item,
            sub_total: discountedItemSubtotal(
              item,
              item.price_modifiers,
              itemSubTotal
            ),
            applied_promotion_id: promotion.id,
            applied_promotion_priority: promotion.priority,
          };
        });
      } else {
        //split items to highest possible multiple of exact quantity
        const appliableItemsCount = Math.floor(
          applicableItemsCount / exactQuantity
        );
        const appliableItems = applicableItems.slice(
          0,
          appliableItemsCount * exactQuantity
        );
        const unappliableItems = applicableItems.slice(
          appliableItemsCount * exactQuantity
        );
        const itemSubTotal =
          (fixedValue * appliableItemsCount) / appliableItems.length;
        promotionAppliedItems = appliableItems.map((item) => {
          return {
            ...item,
            sub_total: discountedItemSubtotal(
              item,
              item.price_modifiers,
              itemSubTotal
            ),
            applied_promotion_id: promotion.id,
            applied_promotion_priority: promotion.priority,
          };
        });
        promotionAppliedItems = [...promotionAppliedItems, ...unappliableItems];
      }
    } else {
      const appliableItemsCount = Math.ceil(applicableItemsCount / maxQuantity);
      const appliableItems = applicableItems.slice(
        0,
        appliableItemsCount * maxQuantity
      );
      const unappliableItems = applicableItems.slice(
        appliableItemsCount * maxQuantity
      );
      let itemSubTotal = 0;
      if (appliableItems.length > maxQuantity) {
        itemSubTotal = fixedValue / maxQuantity;
        promotionAppliedItems = appliableItems.map((item, index) => {
          if (index < maxQuantity) {
            return {
              ...item,
              sub_total: discountedItemSubtotal(
                item,
                item.price_modifiers,
                itemSubTotal
              ),
              applied_promotion_id: promotion.id,
              applied_promotion_priority: promotion.priority,
            };
          } else {
            return {
              ...item,
              applied_promotion_id: promotion.id,
              applied_promotion_priority: promotion.priority,
            };
          }
        });
      } else {
        itemSubTotal =
          (fixedValue * appliableItemsCount) /
          (minQuantity * appliableItemsCount);
        promotionAppliedItems = appliableItems.map((item, index) => {
          if (index % maxQuantity < minQuantity && index < maxQuantity) {
            return {
              ...item,
              sub_total: discountedItemSubtotal(
                item,
                item.price_modifiers,
                itemSubTotal
              ),
              applied_promotion_id: promotion.id,
              applied_promotion_priority: promotion.priority,
            };
          } else {
            return {
              ...item,
              applied_promotion_id: promotion.id,
              applied_promotion_priority: promotion.priority,
            };
          }
        });
      }

      promotionAppliedItems = [...promotionAppliedItems, ...unappliableItems];
    }
  } else {
    return cartItems;
  }
  return [...promotionAppliedItems, ...unapplicableItems];
}

/**
 * Filters the promotion applicable and unapplicable items based on the promotion action's quantity.
 * @param promotionApplicableItems - An array of promotion applicable items.
 * @param promotionUnapplicableItems - An array of promotion unapplicable items.
 * @param promotionActionItemsQuantity - The quantity of items required for the promotion action.
 * @param cartItems - An array of cart items.
 * @returns The filtered promotion applicable and unapplicable items.
 */
const filterAdvancedPromoActionItems = (
  promotionApplicableItems: any[],
  promotionUnapplicableItems: any[],
  promotionActionItemsQuantity: number,
  cartItems: any[]
) => {
  if (
    promotionActionItemsQuantity > 0 &&
    promotionApplicableItems.length === promotionActionItemsQuantity
  ) {
    //exact quantity of items required for promotion action
    return { promotionApplicableItems, promotionUnapplicableItems };
  } else if (
    promotionActionItemsQuantity > 0 &&
    promotionApplicableItems.length > promotionActionItemsQuantity
  ) {
    //more than required quantity of items for promotion so spliting the items
    const { slicedItems, remainingItems } = splitItemsByQuantity(
      promotionApplicableItems,
      promotionActionItemsQuantity
    );
    promotionApplicableItems = slicedItems;
    promotionUnapplicableItems = [
      ...promotionUnapplicableItems,
      ...remainingItems,
    ];
    return { promotionApplicableItems, promotionUnapplicableItems };
  } else {
    //no items required for promotion action
    promotionApplicableItems = [];
    promotionUnapplicableItems = cartItems;
    return { promotionApplicableItems, promotionUnapplicableItems };
  }
};

/**
 * Finds the promotion action items based on the given promotion and cart items.
 *
 * @param promotion - The promotion object.
 * @param cartItems - The array of cart items.
 * @returns An obj of action items and non applicableItems.
 */
function findPromotionActionItems(promotion: any, cartItems: any[]) {
  const { include, exclude } = promotion.action;
  let promotionAppliableItems: any[] = [];
  let promotionUnappliableItems: any[] = [];
  let promotionActionItemsQuantity = promotion.action.quantity;
  let actionItems: any[] = [];
  let allActionItemsIncluded = false;
  if (promotion.action.include) {
    actionItems = findPromotionApplicableItems(
      cartItems,
      promotion.action.include
    );
  }
  if (actionItems.length) {
    allActionItemsIncluded = actionItems.every((actionItem) =>
      cartItems.includes(actionItem)
    );
  }
  if (allActionItemsIncluded) {
    promotionActionItemsQuantity +=  promotion.action.quantity;
  }
  if (include.length === 0 && exclude.length === 0) {
    promotionAppliableItems = filterOutPromotionAppliedItems(
      cartItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionAppliableItems.includes(item)
    );
    if (
      promotion.condition.type ===
      PROMOTION_CONDITION_TYPES.BUYS_FOR_SPECIFIC_AMOUNT
    ) {
      promotionActionItemsQuantity = promotionAppliableItems.length;
    }

    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  } else if (include.length) {
    const promotionIncludeItems = findPromotionApplicableItems(
      cartItems,
      include
    );
    promotionAppliableItems = filterOutPromotionAppliedItems(
      promotionIncludeItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionIncludeItems.includes(item)
    );
    if (
      promotion.condition.type ===
      PROMOTION_CONDITION_TYPES.BUYS_FOR_SPECIFIC_AMOUNT
    ) {
      promotionActionItemsQuantity = promotionAppliableItems.length;
    }
    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  } else {
    const promotionExcludeItems = findPromotionApplicableItems(
      cartItems,
      exclude
    );
    const promotionIncludeItems = cartItems.filter(
      (item) => !promotionExcludeItems.includes(item)
    );
    promotionAppliableItems = filterOutPromotionAppliedItems(
      promotionIncludeItems,
      promotion
    );
    promotionUnappliableItems = cartItems.filter(
      (item) => !promotionIncludeItems.includes(item)
    );
    if (
      promotion.condition.type ===
      PROMOTION_CONDITION_TYPES.BUYS_FOR_SPECIFIC_AMOUNT
    ) {
      promotionActionItemsQuantity = promotionAppliableItems.length;
    }
    return filterAdvancedPromoActionItems(
      promotionAppliableItems,
      promotionUnappliableItems,
      promotionActionItemsQuantity,
      cartItems
    );
  }
}

function applyPromotionAction(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
): any[] {
  const promotionAction = promotion.action;
  let applicableItems: any[] = [];
  let unapplicableItems: any[] = [];
  if (
    !(
      promotionAction.surcharge?.id ||
      promotionAction.type === PROMOTION_ACTIONS.PAY_FIXED_PRICE
    )
  ) {
    const promotionActionItems = findPromotionActionItems(
      promotion,
      promotionAppliedCart
    );
    applicableItems = promotionActionItems.promotionApplicableItems;
    unapplicableItems = promotionActionItems.promotionUnapplicableItems;
  }

  switch (promotionAction.type) {
    case PROMOTION_ACTIONS.PAY_FIXED_PRICE:
      return applyFixedPricePromotion(promotionAppliedCart, promotion);
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER:
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER:
    case PROMOTION_ACTIONS.EARN_LOYALTY:
    case PROMOTION_ACTIONS.GETS_FREE_DELIVERY:
    case PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY:
    case PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY:
      orderPromotions.push(promotion);
      return promotionAppliedCart;
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ITEM:
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT:
      const percentageDiscountAppliedItem = applyAdvancedDiscount(
        applicableItems,
        promotion,
        applyPercentageDiscount
      );
      return [...percentageDiscountAppliedItem, ...unapplicableItems];
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ITEM:
    case PROMOTION_ACTIONS.VALUE_DISCOUNT:
      const valueDiscountAppliedItems = applyAdvancedDiscount(
        applicableItems,
        promotion,
        applyValueDiscount
      );
      return [...valueDiscountAppliedItems, ...unapplicableItems];
    case PROMOTION_ACTIONS.GETS_FREE_ITEM:
      const freeDiscountAppliedItems = applyAdvancedDiscount(
        applicableItems,
        promotion,
        freeItem
      );
      return [...freeDiscountAppliedItems, ...unapplicableItems];
    default:
      return promotionAppliedCart;
  }
}

function splitItemsByQuantity(applicableItems: any[], exactQuantity: number) {
  if (exactQuantity > 0 && exactQuantity < applicableItems.length) {
    const slicedItems = applicableItems.slice(0, exactQuantity);
    const remainingItems = applicableItems.slice(exactQuantity);
    return { slicedItems: slicedItems, remainingItems: remainingItems };
  } else {
    return { slicedItems: applicableItems, remainingItems: [] };
  }
}

function applyBuysSpecificItemsPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  let modifiedPromotionAppliedCart = promotionAppliedCart;
  let {
    min_qty: minQuantity = 0,
    max_qty: maxQuantity = 0,
    include,
    exclude,
  } = promotion.condition;
  let exactQuantity =
    minQuantity > 0 && minQuantity === maxQuantity ? minQuantity : 0;

  const applyPromotionIfEligible = (conditionIncludeCartItems: any[]) => {
    let actionItems: any[] = [];
    let allActionItemsIncluded = false;
    if (promotion.action.include) {
      actionItems = findPromotionApplicableItems(
        promotionAppliedCart,
        promotion.action.include
      );
    }
    if (actionItems.length) {
      allActionItemsIncluded = actionItems.every((actionItem) =>
        conditionIncludeCartItems.includes(actionItem)
      ) && promotion.action.type === PROMOTION_ACTIONS.GETS_FREE_ITEM
    }
    if (promotion.action.type === PROMOTION_ACTIONS.PAY_FIXED_PRICE) {
      return applyPromotionAction(
        promotionAppliedCart,
        promotion,
        orderPromotions
      );
    }
    if (allActionItemsIncluded) {
      const actionItemsQuantity = promotion.action.quantity ?? 1;
      const newExactQuantity =
        exactQuantity > 0 ? exactQuantity + actionItemsQuantity : 0;
      const newMinQuantity = minQuantity + actionItemsQuantity;
      const newMaxQuantity = minQuantity + actionItemsQuantity;
      if (
        (newExactQuantity > 0 &&
          newExactQuantity === conditionIncludeCartItems.length) ||
        (newMinQuantity > 0 &&
          newMaxQuantity > 0 &&
          newMinQuantity <= conditionIncludeCartItems.length &&
          newMaxQuantity >= conditionIncludeCartItems.length)
      ) {
        //only exact quantity of items required for promotion or range between min and max quantity
        return applyPromotionAction(
          promotionAppliedCart,
          promotion,
          orderPromotions
        );
      }
    } else {
      if (
        (exactQuantity > 0 &&
          exactQuantity === conditionIncludeCartItems.length) ||
        (minQuantity > 0 &&
          maxQuantity > 0 &&
          minQuantity <= conditionIncludeCartItems.length &&
          maxQuantity >= conditionIncludeCartItems.length)
      ) {
        //only exact quantity of items required for promotion or range between min and max quantity
        return applyPromotionAction(
          promotionAppliedCart,
          promotion,
          orderPromotions
        );
      }
    }

    return promotionAppliedCart;
  };

  if (include.length === 0 && exclude.length === 0) {
    modifiedPromotionAppliedCart =
      applyPromotionIfEligible(promotionAppliedCart);
  } else if (include.length && exclude.length === 0) {
    const conditionIncludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      include
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionIncludeItemInCart
    );
  } else if (include.length === 0 && exclude.length) {
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionExcludeItemInCart
    );
  } else {
    const conditionIncludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      include
    );
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    const conditionIncludeExcludeItemInCart = conditionIncludeItemInCart.filter(
      (item) => !conditionExcludeItemInCart.includes(item)
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionIncludeExcludeItemInCart
    );
  }

  return { modifiedPromotionAppliedCart, orderPromotions };
}

function applyBuysForSpecificAmountPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  let { include, exclude, min_price: minPrice } = promotion.condition;
  let modifiedPromotionAppliedCart = promotionAppliedCart;

  const applyPromotionIfEligible = (applicableItems: any[]) => {
    const cartSubtotal = findCartSubtotal(applicableItems);
    if (
      promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ITEM ||
      promotion.action.type === PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER
    ) {
      if (minPrice < promotion.action.value) {
        //if min price is less than promotion value then set min price to promotion value
        minPrice = promotion.action.value;
      }
    }
    if (cartSubtotal >= minPrice) {
      return applyPromotionAction(
        promotionAppliedCart,
        promotion,
        orderPromotions
      );
    }
    return promotionAppliedCart;
  };
  if (include.length === 0 && exclude.length === 0) {
    modifiedPromotionAppliedCart =
      applyPromotionIfEligible(promotionAppliedCart);
  } else if (include.length && exclude.length === 0) {
    const conditionIncludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      include
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionIncludeItemInCart
    );
  } else if (include.length === 0 && exclude.length) {
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionExcludeItemInCart
    );
  } else {
    const conditionIncludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      include
    );
    const conditionExcludeItemInCart = findPromotionApplicableItems(
      promotionAppliedCart,
      exclude
    );
    const conditionIncludeExcludeItemInCart = conditionIncludeItemInCart.filter(
      (item) => !conditionExcludeItemInCart.includes(item)
    );
    modifiedPromotionAppliedCart = applyPromotionIfEligible(
      conditionIncludeExcludeItemInCart
    );
  }

  return { modifiedPromotionAppliedCart, orderPromotions };
}

function applyAdvancedPromotion(
  promotionAppliedCart: any[],
  promotion: any,
  orderPromotions: any[]
) {
  let promotionCondition = promotion.condition;
  let modifiedPromotionAppliedCart = promotionAppliedCart;
  if (
    promotionCondition.type === PROMOTION_CONDITION_TYPES.BUYS_SPECIFIC_ITEMS
  ) {
    return applyBuysSpecificItemsPromotion(
      promotionAppliedCart,
      promotion,
      orderPromotions
    );
  } else if (
    promotionCondition.type ===
    PROMOTION_CONDITION_TYPES.BUYS_FOR_SPECIFIC_AMOUNT
  ) {
    return applyBuysForSpecificAmountPromotion(
      modifiedPromotionAppliedCart,
      promotion,
      orderPromotions
    );
  } else {
    return {
      modifiedPromotionAppliedCart: promotionAppliedCart,
      orderPromotions,
    };
  }
}

/**
 * @param cartItems - An array of cart items.
 * @param availablePromotions - An array of available promotions.
 * @returns applied promotions, updated cart items and order promotions
 */
function findAppliedPromotions(cartItems: any[], availablePromotions: any[]) {
  let orderPromotion: any[] = [];
  let promotionAppliedCart: any[] = cartItems;
  let appliedPromotions: any[] = []; //find from cart items last
  availablePromotions.forEach((promotion) => {
    if (promotion.promo_type === PROMOTION_TYPES.BASIC) {
      const basicPromotionData = applyBasicPromotion(
        promotionAppliedCart,
        promotion,
        orderPromotion
      );
      promotionAppliedCart = basicPromotionData.promotionAppliedCart;
      orderPromotion = basicPromotionData.orderPromotion;
    } else if (promotion.promo_type === PROMOTION_TYPES.ADVANCED) {
      const advancedPromotionData = applyAdvancedPromotion(
        promotionAppliedCart,
        promotion,
        orderPromotion
      );
      promotionAppliedCart = advancedPromotionData.modifiedPromotionAppliedCart;
      orderPromotion = advancedPromotionData.orderPromotions;
    }
  });
  appliedPromotions = findCartAppliedPromotions(
    promotionAppliedCart,
    availablePromotions
  );
  promotionAppliedCart = remergeCart(promotionAppliedCart); // undo cart changes
  return {
    appliedPromotions,
    promotionAppliedCart,
    orderPromotion,
  };
}

/**
 *
 * @param promotion
 * @param surchargeBreakdown
 * @returns discount Amount
 */
const calculateDeliveryPromotionDiscount = (
  promotion: any,
  surchargeBreakdown: any[]
) => {
  let promotionDiscount = 0;
  let appliableSurchargeId = promotion.action.surcharge.id;
  const deliveryCharge = surchargeBreakdown.find(
    (charge) => charge.id === appliableSurchargeId
  );
  if (deliveryCharge) {
    let surchargeAmount = deliveryCharge.amount;
    const maxDiscount = surchargeAmount;
    switch (promotion.action.type) {
      case PROMOTION_ACTIONS.GETS_FREE_DELIVERY:
        promotionDiscount = maxDiscount;
        break;
      case PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY:
        promotionDiscount = (surchargeAmount * promotion.action.value) / 100;
        if (promotionDiscount > maxDiscount) {
          promotionDiscount = maxDiscount;
        }
        break;
      case PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY:
        promotionDiscount = promotion.action.value;
        if (promotionDiscount > maxDiscount) {
          promotionDiscount = maxDiscount;
        }
        break;
      default:
        break;
    }
  }
  return promotionDiscount;
};

const calculateOrderPromotionDiscount = (
  promotion: any,
  subtotal: number,
  surchargeTotal: number,
  surchargeBreakdown: any[]
) => {
  let discountAmount = 0;
  let discountableAmount = subtotal + surchargeTotal;
  switch (promotion.action.type) {
    case PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER:
      discountAmount = (discountableAmount * promotion.action.value) / 100;
      const maxDiscount = promotion.action?.max_discount_value ?? 0;
      if (maxDiscount > 0 && discountAmount > maxDiscount) {
        discountAmount = maxDiscount;
      }
      break;
    case PROMOTION_ACTIONS.VALUE_DISCOUNT_ON_ORDER:
      discountAmount = promotion.action.value;
      break;
    case PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY:
    case PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY:
    case PROMOTION_ACTIONS.GETS_FREE_DELIVERY:
      discountAmount = calculateDeliveryPromotionDiscount(
        promotion,
        surchargeBreakdown
      );
    default:
      break;
  }
  return discountAmount;
};

/**
 * Checks if an item in the cart has an applied promotion.
 * @param cartItem - The cart item to check.
 * @param promotionAppliedCart - The array of cart items with applied promotions.
 * @returns applied promotion item else 0
 */
const checkItemHasAppliedPromotion = (
  cartItem: any,
  promotionAppliedCart: any[]
) => {
  const appliedPromotion = promotionAppliedCart.find(
    (item) =>
      item.cart_id === cartItem.cart_id &&
      item.applied_promotion_id !== undefined
  );
  return appliedPromotion ? appliedPromotion.applied_promotion_id : 0;
};

/**
 * Filters out delivery promotions from the given array of promotions.
 * @param promotions - The array of promotions.
 * @returns The filtered array of promotions.
 */
function filterOutDeliveryPromotions(promotions: any[]) {
  return promotions.filter(
    (promotion) =>
      promotion.action.type !== PROMOTION_ACTIONS.GETS_FREE_DELIVERY &&
      promotion.action.type !==
        PROMOTION_ACTIONS.GETS_PERCENTAGE_DISCOUNT_ON_DELIVERY &&
      promotion.action.type !==
        PROMOTION_ACTIONS.GETS_VALUE_DISCOUNT_ON_DELIVERY
  );
}

/**
 * Transforms the order promotion to Discount object
 * @param orderPromotion - The order promotion object.
 * @returns The Discount object.
 */
function transformOrderPromotionToDiscount(
  orderPromotion: any,
  subTotal: string,
  surchargeTotal: string,
  surchargesBreakdown: any[]
) {
  const isDeliveryPromotion = orderPromotion.action.surcharge;
  const orderSubTotal = parseFloat(subTotal);
  const orderSurchageTotal = parseFloat(surchargeTotal);
  const isPercentageDiscount =
    orderPromotion.action.type ===
    PROMOTION_ACTIONS.PERCENTAGE_DISCOUNT_ON_ORDER;
  const discountAmount = calculateOrderPromotionDiscount(
    orderPromotion,
    orderSubTotal,
    orderSurchageTotal,
    surchargesBreakdown
  );
  return {
    name: orderPromotion.name,
    type: isPercentageDiscount ? DISCOUNT_TYPE.PERCENTAGE : DISCOUNT_TYPE.VALUE,
    discount_type: isPercentageDiscount ? 1 : 2, //1 is percentage and 2 is value
    on_total: false,
    rate: isDeliveryPromotion ? discountAmount : orderPromotion.action.value,
    amount: discountAmount,
    movement_type: orderPromotion.movement_type ?? "sold",
    promotion_id: orderPromotion.id,
  };
}

function transformItemPromotionToDiscount(itemPromotion: any, item: any) {
  const promotionUnappliedCart = JSON.parse(localStorage.cartItems || "[]");
  const promotionUnappliedItem = promotionUnappliedCart.find(
    (cartItem: any) => cartItem.cart_id === item.cart_id
  );
  if (promotionUnappliedItem) {
    const itemSubTotalBeforePromotion = promotionUnappliedItem.sub_total;
    const discountAmount = itemSubTotalBeforePromotion - item.sub_total;
    return {
      name: itemPromotion.name,
      type: DISCOUNT_TYPE.VALUE,
      rate: discountAmount,
      amount: discountAmount,
      promotion_id: itemPromotion.id,
      movement_type: itemPromotion.movement_type ?? "sold",
    };
  }
  return null;
}

function removeUnavailablePromotionId(cartItems: any[], promotions: []) {
  let updatedCartItems: any[] = cartItems.map((item: any) => {
    if (item.applied_promotion_id) {
      let appliedPromotionId = item.applied_promotion_id;
      let isPromotionAvailable = promotions.find(
        (promotion: any) => promotion.id === appliedPromotionId
      );
      if (!isPromotionAvailable) {
        delete item.applied_promotion_id;
        delete item.item.applied_promotion_id;
      }
      return item;
    } else {
      return item;
    }
  });
  return updatedCartItems;
}

function removePromotions(pastOrderItems: any, payloadItems: any) {
  let transformedItems: any[] = [];
  if (pastOrderItems.length) {
    transformedItems = pastOrderItems.map((item: any) => {
      if (item.applied_promotion_id) {
        //removing the changes done in cartItems for promotions
        delete item.applied_promotion_id;
        delete item.applied_promotion_priority;
        let itemPayloadData = payloadItems.find(
          (payloaditem: any) => payloaditem.id === item.id
        );
        if (itemPayloadData) {
          item.sub_total = itemPayloadData.sub_total;
        }
      }
      return item;
    });
  }
  return transformedItems;
}

export {
  appendCategoryDetails,
  duplicateCartItems,
  findCartSubtotal,
  sortPromotions,
  findAppliedPromotions,
  calculateOrderPromotionDiscount,
  checkItemHasAppliedPromotion,
  filterOutDeliveryPromotions,
  transformOrderPromotionToDiscount,
  transformItemPromotionToDiscount,
  removeUnavailablePromotionId,
  removePromotions,
};
