import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import OTPInput from "../../../mobileComponents/otpInput";
import OtpTimer from "../../otpTimer";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userOperations } from "../../../state/features/user";

interface IMobileVerificationProps {
  onClose: () => void;
  hideModal: (value: boolean) => void;
  userPayload: any;
  resetEmail: (data: any, token: string) => Promise<any>;
  sendOtpViaEmail: (payload: any, token: string) => Promise<any>;
  resendOtpViaEmail: (payload: any, token: string) => Promise<any>;
  onSuccess: () => void;
}

function EmailVerification(props: IMobileVerificationProps) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState<string | null>(null);
  const [otpTimeout, setOtpTimeout] = useState(false);
  const [newOtpRequested, setNewOtpRequested] = useState(false);
  const userRef = useRef<any>(null);
  const csrfToken = document.querySelector('[name=csrf-token]')?.content;

  useEffect(() => {
    sendOtp();
  }, []);

  const sendOtp = () => {
    const otpAction = props.sendOtpViaEmail({ email: props.userPayload.email }, csrfToken);

    otpAction.then((response: any) => {
      if (response.error) {
        handleClose();
      }
    });
  };

  const resendOtp = () => {
    setOtpError(null);
    const otpAction = props.resendOtpViaEmail({ email: props.userPayload.email }, csrfToken);
    
    otpAction.then((response: any) => {
      if (!response.error) {
        setNewOtpRequested(true);
        props.hideModal(false);
        setOtpTimeout(false);
      } else {
        handleClose();
      }
    });
  };

  const handleClose = () => {
    props.onClose();
  };

  // Modified error message handler
  const errorMessage = (statusCode: string) => {
    switch (statusCode) {
      case "otp_error":
        return (
          <FormattedMessage
            id="registration.otp.incorrect_otp"
            defaultMessage="Incorrect OTP."
          />
        );
      default:
        return (
          <FormattedMessage
            id="registration.otp.something_went_wrong"
            defaultMessage="Oops, something went wrong. Please try again."
          />
        );
    }
  };

  const submitOtp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData = { email: props.userPayload.email, otp };

    props.resetEmail(userData, csrfToken).then((response: any) => {
      if (!response.error) {
        userRef.current = response.payload.data;
        props.onSuccess();
        handleClose();
      } else {
        // Setting the otpError based on the status code received from the response
        setOtpError(errorMessage(response.error.response.data.id));
        props.hideModal(false);
      }
    });
  };

  const handleOTPInput = (otp: string) => {
    setOtpError(null);
    setOtp(otp);
  };

  return (
    <div className="user-login px-3 py-5">
      <h1 className="header">
        <FormattedMessage id="registration.otp.enter_the_otp_code" defaultMessage="Enter the OTP Code" />
      </h1>
      <div>
        <h3 className="mt-2 mb-3 text-center">
          <FormattedMessage
            id="registration.otp.enter_code_sent_to_email"
            defaultMessage="Please enter code we sent to <pTag>{email}</pTag>."
            values={{
              email: props.userPayload.email,
              pTag: (email: any) => <p className="font-weight-bold">{email}</p>,
            }}
          />
        </h3>
        {newOtpRequested && (
          <p className="otp-resent-info">
            <FormattedMessage id="registrations.otp.resend_requested" defaultMessage="A new OTP has been sent" />
          </p>
        )}
        <form className="text-center" onSubmit={submitOtp}>
          <OTPInput
            dir="ltr"
            autoFocus
            length={4}
            type="number"
            className="otpContainer"
            inputClassName="otpInput form-control"
            onChangeOTP={handleOTPInput}
          />
          {otpError && (
            <div className="input-label text-center text-danger">
              {otpError}
            </div>
          )}
          <button className="btn button btn-primary w-100 py-2 mt-4 mb-3">
            <FormattedMessage id="global.continue" defaultMessage="Continue" />
            <span className="ml-3">
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </span>
          </button>
          <p className="other-actions otp-resend">
            <FormattedMessage id="registration.otp.receive_question" defaultMessage="Didn't receive the OTP?" />
          </p>
          {!otpTimeout ? (
            <p className="other-actions otp-wait">
              <OtpTimer setOtpTimeout={setOtpTimeout} />
            </p>
          ) : (
            <p className="other-actions otp-resend-action py-2" onClick={resendOtp}>
              <FormattedMessage id="registrations.otp.resend_button" defaultMessage="Resend" />
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  resetEmail: userOperations.resetEmail,
  sendOtpViaEmail: userOperations.sendOtpViaEmail,
  resendOtpViaEmail: userOperations.resendOtpViaEmail,
};

export default connect(null, mapDispatchToProps)(EmailVerification);
