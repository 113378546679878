// Packages
import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import DiscountWidgetMobile from "../../mobileComponents/discountWidgetMobile";
import LoyaltyWidgetMobile from "../../mobileComponents/loyaltyWidgetMobile";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faMinus,
  faPlus,
  faTag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { cartOperations } from "../../state/features/cart";
import { pageOperations } from "../../state/features/page";
import { loaderOperations } from "../../state/features/loader";
import { errorOperations } from "../../state/features/error";
import { areasOperations } from "../../state/features/areas";
// Helpers, Utils etc.
import {
  formatDecimal,
  calculateAmountsAndBreakdowns,
} from "../../helpers/itemCalculations";
import {
  updateItemWithCartIdInCart,
  removeItemWithCartId,
} from "../../helpers/cartFunctions";
import { validateCartItems, outOfStockItemsList } from "../../helpers/cartFunctions";
import {
  translatedName,
} from "../../helpers/translations";
import OutOfStockWarning from "../../mobileComponents/outOfStockWarningMobile";
import PaymentInProgressBanner from "../../mobileComponents/paymentInProgressBanner";
import PromotionBannerMobile from "../../mobileComponents/promotionBannerMobile";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";
import { checkItemHasAppliedPromotion } from "../../features/promotions/helpers/utils";

interface IOrderDetailsScreenProps {
  cart: any;
  setCart: any;
  menu: any;
  orderType: any;
  currency: any;
  hasLoyalty: boolean;
  redeemedPoints: any;
  discount: any;
  user: any;
  loyalty: any;
  deliveryAreas: any;
  areaId: any;
  outlet: any;
  storeOpen: boolean;
  location: any;
  selectedArea: any;
  showAreaSelect: boolean;
  fetchOrderDetailsConfigurations: any;
  showLoader: any;
  updateError: any;
  setArea: any;
  outOfStock: any[];
  recentOrder: any;
  availablePromotions: any[];
  appliedPromotions: any[];
  validateCart(cart: any[]): Promise<any>;
  hideLoader(): void;
}

function OrderDetailsScreen(props: IOrderDetailsScreenProps) {
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [amountToReachMinOrderValue, setAmountToReachMinOrderValue] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState({} as any);
  const { appliedPromotionsData, cartVerificationPayload } =
    usePromotionsContext();

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const cartDetails = useMemo(() => {
    const promotionAppliedCart = appliedPromotionsData.promotionAppliedCart
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      promotionAppliedCart,
      props.menu,
      props.orderType,
      props.discount,
      props.redeemedPoints,
      props.selectedArea,
      props.outlet.delivery_charge_id,
      0, // tips is 0
      appliedPromotionsData.orderPromotion
    );
    let subTotal = amountBreakdowns?.subTotal ?? 0;
    let total = amountBreakdowns?.total ?? 0;
    let taxTotal = amountBreakdowns?.taxTotal ?? 0;
    let surchargeBreakDown = amountBreakdowns.surchargeBreakDown ?? [];
    let orderPromotionDiscount = amountBreakdowns.orderPromotionDiscount ?? 0;
    let appliedPromotionName = "";
    if (
      appliedPromotionsData.orderPromotion &&
      appliedPromotionsData.orderPromotion.length > 0
    ) {
      appliedPromotionName = appliedPromotionsData.orderPromotion[0].name;
    }

    return {
      subTotal,
      total,
      taxTotal,
      surchargeBreakDown,
      orderPromotionDiscount,
      appliedPromotionName,
    }
  }, [props.cart, appliedPromotionsData]);

  useEffect(() => {
    setCartItems(appliedPromotionsData.promotionAppliedCart);
    if (props.deliveryAreas && props.orderType === "delivery") {
      findDeliveryArea();
      checkMinOrderValue(cartDetails.subTotal);
    }
  }, [
    JSON.stringify(appliedPromotionsData.promotionAppliedCart),
    props.areaId,
    cartDetails.subTotal,
  ]);

  useEffect(() => {
    props.fetchOrderDetailsConfigurations().then((response: any) => {
      if (!response.error) {
        updateItemsInCart(
          validateCartItems(
            response.payload.data.menu.categories,
            JSON.parse(localStorage.cartItems || "[]"),
            itemsNotInMenuError
          )
        );
      }
    });
    verifyPaymentStatus();
  }, []);

  useEffect(() => {
    if (props.showAreaSelect) {
      props.updateError({
        titleCode: "error.oops",
        messageCode: "error.unable_to_process_order",
        redirectionUrl: "/m",
      });
    }
  }, [props.showAreaSelect]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateItemsInCart(props.cart.items);
  }, [props.cart.items, appliedPromotionsData]);

  const findDeliveryArea = () => {
    let area = props.deliveryAreas.find((area: any) => {
      return area.id == props.areaId;
    });
    props.setArea(area);
  }

  const outOfStockInCart = useMemo(() => {
   return outOfStockItemsList(cartItems, props.outOfStock);
  },[cartItems]);

  const checkMinOrderValue = (totalAmount: any) => {
    if (Number(totalAmount) < Number(props.selectedArea.min_order_value)) {
      setAmountToReachMinOrderValue(
        Number(props.selectedArea.min_order_value) - Number(totalAmount)
      );
    } else {
      setAmountToReachMinOrderValue(0)
    }
  };

  const updateItemsInCart = (cartItems: any) => {
    let cart = calculateAmountsAndBreakdowns(
      appliedPromotionsData.promotionAppliedCart,
      props.menu,
      props.orderType,
      props.discount,
      props.redeemedPoints,
      props.selectedArea,
      props.outlet.delivery_charge_id,
      0,
      appliedPromotionsData.orderPromotion
    ) as any;
    cart.items = cartItems;

    props.setCart(cart);
  };

  const itemsNotInMenuError = () => {
    props.updateError({
      title: intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
      message: intl.formatMessage({ id: "error.items_in_cart_not_in_menu", defaultMessage: "Items in cart are not part of current available menu." }),
    });
  }

  const verifyPaymentStatus = () => {
    let urlParams = new URLSearchParams(props.location.search);
    let paymentStatus = urlParams.get("payment_status");
    let message = urlParams.get("message");

    if (paymentStatus == "failed") {
      setPaymentDetails({
        status: paymentStatus,
        message: message,
      });
    }
  }

  const incrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const decrementCount = (item: any) => {
    item.count = item.count - 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const removeItem = (item: any) => {
    removeItemWithCartId(item, props.cart.items, props.setCart);
  };

  let modifierBreakdown = (menuItem: any) =>
    menuItem.groups.map((group: any) => {
      let groups = group.items.map((item: any) => {
        let selected_item;
        if (item.selected === true) {
          selected_item = item;
        }
        return (
          selected_item && (
            <div className="modifier" key={selected_item.id}>
                {translatedName(selected_item, intl.locale)}
            </div>
          )
        );
      });
      return groups;
    });

    const handleCartValidationFailure = () => {
      dispatch(
        errorOperations.updateError({
          title: intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
          message: intl.formatMessage({
            id: "error.something_went_wrong",
            defaultMessage: "Something went wrong. Please try again later.",
          }),
        })
      );
    };

  const findAppliedItemPromotion = (item: any, promotionAppliedCart: any[]) => {
    let appliedPromoId = checkItemHasAppliedPromotion(item, promotionAppliedCart);
    if(appliedPromoId){
      return props.availablePromotions.find((promo: any) => promo.id === appliedPromoId);
    }
    return null;
  }

  const onCheckoutClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.showLoader();
    if (!props.user?.id) {
      localStorage.setItem("postRegistrationPage", "/m/checkout");
      history.push("/m/login");
      props.hideLoader();
    } else {
      try {
        props.showLoader();
        const result = await props.validateCart(cartVerificationPayload);
        if (!result.error && result.payload.data.validated) {
          history.push("/m/checkout");
        } else {
          props.hideLoader();
          handleCartValidationFailure();
        }
      } catch (error) {
        handleCartValidationFailure();
      } finally {
        props.hideLoader();
      }
    }
  };

  const disableCheckoutButton = useMemo(() => {
    return (
      cartItems?.length === 0 &&
      amountToReachMinOrderValue &&
      !props.storeOpen &&
      outOfStockInCart?.length > 0 &&
      props.recentOrder
    );
  }, [
    cartItems,
    amountToReachMinOrderValue,
    props.storeOpen,
    outOfStockInCart,
    props.recentOrder,
  ]);

  return (
    <div className="order-details-screen mt-0">
      <PromotionBannerMobile />
      {paymentDetails.status == "failed" ? (
        <div className="payment-failure">
          <p className="error-header">
            <FormattedMessage
              id="payment.payment_failed"
              defaultMessage="Payment Failed"
            />
          </p>
          <p className="error-message">
            <FormattedMessage
              id="payment.unable_to_process_payment"
              defaultMessage="Sorry, we were unable to process your payment. Your card was not charged. Please try again or use a different payment method."
            />
          </p>
          <p className="error">{paymentDetails.message}</p>
        </div>
      ) : null}

      {outOfStockInCart.length ? (
        <div className="out-of-stock-warning">
          <OutOfStockWarning outOfStockItems={outOfStockInCart} />
        </div>
      ) : null}

      <div className="order-details">
        {cartItems.map((cartItem: any, index: any) => {
          return (
            <div key={index}>
              <div
                className={
                  cartItem.groups.length
                    ? "order-item with-group"
                    : "order-item"
                }
              >
                <div
                  className="delete-icon"
                  onClick={() => removeItem(cartItem)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div
                  className={`${
                    props.menu.out_of_stock_items.includes(cartItem.id)
                      ? "out-of-stock-item-details"
                      : ""
                  } item-details`}
                >
                  <p className="item-name">
                    {translatedName(cartItem, intl.locale)}
                  </p>
                  <p className="item-price">
                    {props.currency}{" "}
                    {formatDecimal(cartItem.sub_total / cartItem.count)}
                  </p>
                  {(() => {
                    const appliedPromotion = findAppliedItemPromotion(
                      cartItem,
                      appliedPromotionsData.promotionAppliedCart
                    );
                    return appliedPromotion ? (
                      <p className="item-promotion-name">
                        <span>
                          <FontAwesomeIcon icon={faTag} />{" "}
                          {appliedPromotion.name}
                        </span>
                      </p>
                    ) : null;
                  })()}
                  <p className="item-promo-info"></p>
                </div>
                <div className="count-controller">
                  <p
                    className="icon"
                    onClick={() => {
                      if (props.menu.out_of_stock_items.includes(cartItem.id)) {
                        return;
                      }
                      cartItem.count > 1
                        ? decrementCount(cartItem)
                        : removeItem(cartItem);
                    }}
                  >
                    <FontAwesomeIcon size="xs" icon={faMinus} />
                  </p>
                  <p className="count">{cartItem.count}</p>
                  <p className="icon" onClick={() => incrementCount(cartItem)}>
                    <FontAwesomeIcon size="xs" icon={faPlus} />
                  </p>
                </div>
              </div>
              {cartItem.groups.length ? (
                <div className="selected-modifiers">
                  {modifierBreakdown(cartItem)}
                </div>
              ) : (
                false
              )}
            </div>
          );
        })}
      </div>
      <div className="other-details footer">
        {props.appliedPromotions.length === 0 ? <DiscountWidgetMobile /> : null}
        {props.hasLoyalty ? <LoyaltyWidgetMobile /> : null}
        <div className="charges-breakdown">
          <div className="charge">
            <p>
              <FormattedMessage
                id="global.subtotal"
                defaultMessage="Subtotal"
              />
            </p>
            <p>
              {props.currency} {formatDecimal(cartDetails.subTotal)}
            </p>
          </div>
          {cartDetails.surchargeBreakDown.map((surcharge: any, index: any) => {
            return (
              <div className="charge" key={index}>
                <p className="label">
                  <FormattedMessage
                    id="global.surcharge"
                    defaultMessage="Surcharge"
                  />
                </p>
                <p className="value">
                  {props.currency} {formatDecimal(surcharge.amount)}
                </p>
              </div>
            );
          })}
          {cartDetails.orderPromotionDiscount > 0 ? (
            <div className="charge promotion">
              <p className="label">
                <FontAwesomeIcon icon={faTag} />{" "}
                {cartDetails.appliedPromotionName}
              </p>
              <p className="value">
                - {props.currency}{" "}
                {formatDecimal(cartDetails.orderPromotionDiscount)}
              </p>
            </div>
          ) : null}
          {props.discount ? (
            <div className="charge">
              <p className="label">{props.discount.discount_name}</p>
              <p className="value">
                - {props.currency}{" "}
                {formatDecimal(props.discount.discountedAmount)}
              </p>
            </div>
          ) : null}
          {props.redeemedPoints ? (
            <div className="charge">
              <p className="label">
                <FormattedMessage
                  id="global.loyalty"
                  defaultMessage="Loyalty"
                />
              </p>
              <p className="value">
                - {props.currency} {formatDecimal(props.redeemedPoints)}
              </p>
            </div>
          ) : null}
          {!props.menu.is_tax_inclusive && cartDetails.taxTotal > 0 ? (
            <div className="charge">
              <p>
                <FormattedMessage id="global.tax" defaultMessage="Tax" />
              </p>
              <p>
                {props.currency} {formatDecimal(cartDetails.taxTotal)}
              </p>
            </div>
          ) : null}
          <div className="charge strong">
            <p>
              <FormattedMessage id="global.total" defaultMessage="Total" />
            </p>
            <p>
              {props.currency} {formatDecimal(cartDetails.total)}
            </p>
          </div>
          {amountToReachMinOrderValue ? (
            <div className="minimumAmountNotice">
              <FormattedMessage
                id="checkout.add_more_to_checkout"
                defaultMessage="Add {currency} {amount} more to Checkout"
                values={{
                  currency: props.currency,
                  amount: formatDecimal(amountToReachMinOrderValue),
                }}
              />
            </div>
          ) : null}

          {props.recentOrder ? (
            <div className="in-progress-banner-wrapper">
              <PaymentInProgressBanner />
            </div>
          ) : null}

          <div className="d-flex">
            <Link
              onClick={() => props.showLoader()}
              to="/m"
              className={"button btn btn-outline-primary w-100 mr-3"}
            >
              <FormattedMessage
                id="cart.add_more_items"
                defaultMessage="Add more items"
              />
            </Link>
            <button
              disabled={disableCheckoutButton}
              onClick={(e) => onCheckoutClick(e)}
              className={
                disableCheckoutButton
                  ? "button btn btn-secondary disabled w-100"
                  : "button btn btn-primary w-100"
              }
            >
              <FormattedMessage
                id="global.checkout"
                defaultMessage="Checkout"
              />
              <span className="ml-3">
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;
  let menu = state.menu;
  let cart = state.cart;
  let orderType = state.session.order_type;
  let loyaltyConfig = state.loyaltyConfig || {};
  let hasLoyalty = loyaltyConfig[orderType]?.enabled;
  let redeemedPoints = state.loyalty?.redeemedPoints;
  let discount = state.discount;
  let user = state.user;
  let deliveryAreas = state.outlet?.delivery_areas;
  let areaId = state.session.area_id;
  let outlet = state.outlet;
  let loyalty = state.loyalty;
  let storeOpen = state.storeTimings.open;
  let selectedArea = state.areas.selectedArea;
  let showAreaSelect = state.session.showAreaSelect;
  let outOfStock = state.menu.out_of_stock_items;
  let recentOrder = state.recentOrder;
  let availablePromotions = state.promotions.availablePromotions;
  let appliedPromotions = state.promotions.appliedPromotions;;

  return {
    currency,
    cart,
    menu,
    orderType,
    hasLoyalty,
    redeemedPoints,
    discount,
    user,
    deliveryAreas,
    areaId,
    outlet,
    loyalty,
    storeOpen,
    selectedArea,
    showAreaSelect,
    outOfStock,
    recentOrder,
    availablePromotions,
    appliedPromotions,
  };
};

const mapDispatchToProps = {
  setCart: cartOperations.setCart,
  fetchOrderDetailsConfigurations:
    pageOperations.fetchOrderDetailsConfigurations,
  showLoader: loaderOperations.showLoader,
  updateError: errorOperations.updateError,
  setArea: areasOperations.setArea,
  validateCart: cartOperations.validateCart,
  hideLoader: loaderOperations.hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsScreen);
