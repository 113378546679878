import * as pageTypes from "../page/types";
import * as types from "./types";

const initialState = {
  availablePromotions: [] as any,
  appliedPromotions: [] as any,
  showPromotionsModal: false,
  showAppliedPromotionsModal: false,
  promotionAppliedCart: [] as any,
  appliedOrderPromotion: [] as any,
};
const promotionsReducer = (
  state: typeof initialState = initialState,
  action: any
) => {
  switch (action.type) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      let availablePromotions = action.payload?.data?.promotions ?? [];
      return {
        ...state,
        availablePromotions: availablePromotions
      }
    case types.UPDATE_APPLIED_PROMOTIONS:
      return {
        ...state,
        appliedPromotions: action.payload
      }
    case types.TOGGLE_PROMOTION_MODAL:
      return {...state, showPromotionsModal: !state.showPromotionsModal}
    case types.UPDATE_PROMOTION_APPLIED_CART:
      return {
        ...state,
        promotionAppliedCart: action.payload
      }
    case types.UPDATE_APPLIED_ORDER_PROMOTIONS:
      return {
        ...state,
        appliedOrderPromotion: action.payload
      }
    default: return state;
  }
};

const reducer = promotionsReducer;

export default reducer;
