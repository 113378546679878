import * as types from "./types";

const addRiderTip = (tipAmount: number) => ({
  type: types.ADD_RIDER_TIP,
  payload: tipAmount,
});

const removeRiderTip = () => ({
  type: types.REMOVE_RIDER_TIP,
});

export default {
  addRiderTip,
  removeRiderTip,
};
