import actions from "./actions";

const setUser = actions.setUser;
const updateProfile = actions.updateProfile;
const fetchOrderInProgress = actions.fetchOrderInProgress
const signOut = actions.signOut;
const verifyUser = actions.verifyUser;
const signUp = actions.signUp;
const signIn = actions.signIn;
const sendOtp = actions.sendOtp;
const resendOtp = actions.resendOtp;
const sendOtpViaEmail = actions.sendOtpViaEmail;
const resendOtpViaEmail = actions.resendOtpViaEmail;
const resetEmail = actions.resetEmail;

export default {
  setUser,
  updateProfile,
  fetchOrderInProgress,
  signOut,
  verifyUser,
  signUp,
  signIn,
  sendOtp,
  resendOtp,
  sendOtpViaEmail,
  resendOtpViaEmail,
  resetEmail,
};
