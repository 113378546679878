import * as actions from "./actions"
const toggleShowPromotionModal = actions.toggleShowPromotionModal;
const updatedAppliedPromotion = actions.updatedAppliedPromotion;
const updatePromotionAppliedCart = actions.updatePromotionAppliedCart;
const updateAppliedOrderPromotions = actions.updateAppliedOrderPromotions;

export default{
  toggleShowPromotionModal,
  updatedAppliedPromotion,
  updatePromotionAppliedCart,
  updateAppliedOrderPromotions,
}
