// Packages
import React, { useState, useEffect, useMemo } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import NoImageAvailableIcon from "../../images/NoImageAvailableIcon.png";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import { orderItems } from "../../helpers/orderPayloadBuilder";
import {
  translatedName,
} from "../../helpers/translations";
import OutOfStockWarning from "../outOfStockWarning";
import { outOfStockItemsList } from "../../helpers/cartFunctions";
import { isItemOutOfStock } from "../../helpers/utils";

interface ICartItemsWidgetProps {
  cart: any;
  currency: any;
  outOfStockItems: any;
}

function CartItemsWidget(props: ICartItemsWidgetProps) {
  const cart = useMemo(() => {
    if (props.cart) {
      let updatedCart = Object.assign({}, props.cart);
      updatedCart.items = orderItems(updatedCart.items);
      return updatedCart;
    }
    return null;
  }, [props.cart]);

  const intl = useIntl();

  const outOfStockCartItems = useMemo(()=>{
    return outOfStockItemsList(props.cart.items, props.outOfStockItems)
  }, [props.cart.items, props.outOfStockItems])

  return (
    cart && cart.items.length ? (
      <div className="past-order-details no-padding">
        <h1 className="section-heading">
          <FormattedMessage
            id="order.summary"
            defaultMessage="Order Summary"
          />
        </h1>
        {outOfStockCartItems.length ? (
          <div className="out-of-stock-message">
            <OutOfStockWarning outOfStockItems={outOfStockCartItems} />
          </div>
        ) : null}
        <table className="order-details-table">
          <thead>
            <tr>
              <th className="name">
                <FormattedMessage
                  id="global.item"
                  defaultMessage="Item"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.quantity"
                  defaultMessage="Quantity"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.price"
                  defaultMessage="Price"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.total"
                  defaultMessage="Total"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {cart.items.map((item: any, index: number) => {
              const itemOutOfStock = isItemOutOfStock(item, props.outOfStockItems)
              return (
                <tr key={index} className={itemOutOfStock ? "item-disabled" : ""}>
                  <td
                    className="name"
                    style={
                      item.modifiers.length
                        ? { verticalAlign: "top" }
                        : { verticalAlign: "center" }
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div
                        className={
                          item.image_url ? "item-image" : "item-image hidden"
                        }
                        style={{
                          backgroundImage: `url(${
                            item.image_url ? item.image_url : NoImageAvailableIcon
                          })`,
                        }}
                      />
                      <div>
                        <p>{translatedName(item, intl.locale)}</p>
                        {item.modifiers.length ? (
                          <div className="modifiers">
                            {item.modifiers.map((modifier: any, index: number) => {
                              return (
                                <p className="modifier-item" key={index}>
                                  {translatedName(modifier, intl.locale)}
                                  {modifier.unit_price > 0 ? (
                                    <span className="price">
                                      +{formatDecimal(modifier.unit_price)}{" "}
                                      {props.currency}
                                    </span>
                                  ) : " "}
                                  {"x " + modifier.quantity}
                                </p>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td
                    style={
                      item.modifiers.length
                        ? { verticalAlign: "top" }
                        : { verticalAlign: "center" }
                    }
                  >
                    {item.quantity}
                  </td>
                  <td
                    style={
                      item.modifiers.length
                        ? { verticalAlign: "top" }
                        : { verticalAlign: "center" }
                    }
                  >
                    {formatDecimal(item.sub_total / item.quantity)}
                  </td>
                  <td
                    style={
                      item.modifiers.length
                        ? { verticalAlign: "top" }
                        : { verticalAlign: "center" }
                    }
                  >
                    {formatDecimal(item.sub_total)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : (
      null
    )
  );
}

export default CartItemsWidget;
