import React from "react";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { promotionsOperations } from "../../state/features/promotions";

const PromotionBanner: React.FC = (): React.ReactElement | null => {
  const { showPromotionBanner } = usePromotionsContext();
  const dispatch = useDispatch();

  if (!showPromotionBanner) {
    return null;
  }

  const handleClick = () => {
    dispatch(promotionsOperations.toggleShowPromotionModal());
  }

  return (
    <div className="promotion-banner">
      <div className="promotion-banner-content">
        <span>
          <FormattedMessage id="promotions.promotion_available" />{" "}
          <span className="promotion-banner-link" onClick={handleClick}>
            <FormattedMessage id="promotion.show_details" />
          </span>
        </span>
      </div>
    </div>
  );
};

export default PromotionBanner;
