// List of countries with postal code selector
const POSTAL_CODE_ENABLED_COUNTRIES = ["SG"];

// List of countries with google map based area selector
const GOOGLE_MAPS_ENABLED_COUNTRIES = ["GB", "GG", "IM", "JE", "MY", "PA", "OM", "SA"];

// Payment Types
const CASH_ON_DELIVERY = "cash_on_delivery";
const PAYMENT_PENDING = "pending";
const PAYMENT_PAID = "paid";
const CARD_ON_DELIVERY = "card_on_delivery";
const MASTERCARD_PAYMENT_TYPE = "mastercard"

// Payment Type IDs
const PAYMENT_TYPE_QPAY = 5;
const PAYMENT_TYPE_PAY_U = 6;
const PAYMENT_TYPE_CC_AVENUE = 7;
const PAYMENT_TYPE_I_PAY88 = 8;
const PAYMENT_TYPE_MY_FATOORAH = 10;
const PAYMENT_TYPE_DPO = 11;
const PAYMENT_TYPE_FISERV = 12;
const PAYMENT_TYPE_OMISE = 13;
const PAYMENT_TYPE_ADYEN = 14;
const PAYMENT_TYPE_GEIDEA = 15;

// Surcharges
const FIXED_SURCHARGE = "FIXED";

// Order Types
const ORDER_TYPE_DELIVERY = "delivery";
const ORDER_TYPE_PICKUP = "pickup";

// Email
const EMAIL_REGEX = new RegExp(/^[^@\s]+@([^@\s]+\.)+[^@\s]+$/);
// Name
const NAME_REGEX = new RegExp(/^[^@]+$/);

export {
  POSTAL_CODE_ENABLED_COUNTRIES,
  GOOGLE_MAPS_ENABLED_COUNTRIES,
  CASH_ON_DELIVERY,
  PAYMENT_PENDING,
  PAYMENT_PAID,
  CARD_ON_DELIVERY,
  PAYMENT_TYPE_PAY_U,
  PAYMENT_TYPE_CC_AVENUE,
  PAYMENT_TYPE_MY_FATOORAH,
  PAYMENT_TYPE_GEIDEA,
  FIXED_SURCHARGE,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_PICKUP,
  PAYMENT_TYPE_DPO,
  PAYMENT_TYPE_QPAY,
  PAYMENT_TYPE_I_PAY88,
  PAYMENT_TYPE_FISERV,
  PAYMENT_TYPE_OMISE,
  PAYMENT_TYPE_ADYEN,
  EMAIL_REGEX,
  NAME_REGEX,
  MASTERCARD_PAYMENT_TYPE,
};
