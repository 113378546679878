import { v4 as uuidv4 } from "uuid";
import { calculateSubTotal } from "./itemCalculations";
import { isItemOutOfStock } from "./utils";
import { removePromotions } from "../features/promotions/helpers/utils";

const addItemToCart = (item: any, cartItems: any, cartUpdateCallBack: any) => {
  item.cart_id = uuidv4();
  item.count = 1;
  item.sub_total = calculateSubTotal(item);
  cartItems.push(item);
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
  return item;
};

const addModifiedItemToCart = (
  item: any,
  cartItems: any,
  cartUpdateCallBack: any
) => {
  item.cart_id = uuidv4();
  item.sub_total = calculateSubTotal(item);
  cartItems.push(item);
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
  return item;
};

const updateItemWithCartIdInCart = (
  item: any,
  cartItems: any,
  cartUpdateCallBack: any
) => {
  item.sub_total = calculateSubTotal(item);
  cartItems = cartItems.map((cartItem: any) => {
    if (cartItem.cart_id === item.cart_id) {
      cartItem.count = item.count;
      cartItem.sub_total = item.sub_total;
    }
    return cartItem;
  });
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
  return item;
};

const updateItemWithIdInCart = (
  item: any,
  cartItems: any,
  cartUpdateCallBack: any
) => {
  if (!item.count || item.count === 0) {
    return removeItemWithId(item, cartItems, cartUpdateCallBack);
  }

  item.sub_total = calculateSubTotal(item);
  cartItems = cartItems.map((cartItem: any) => {
    if (cartItem.id === item.id) {
      cartItem.count = item.count;
      cartItem.sub_total = item.sub_total;
    }
    return cartItem;
  });
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
  return item;
};

const removeItemWithCartId = (
  item: any,
  cartItems: any,
  cartUpdateCallBack: any
) => {
  cartItems = cartItems.filter((cartItem: any) => {
    return cartItem.cart_id !== item.cart_id;
  });
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
};

const removeItemWithId = (
  item: any,
  cartItems: any,
  cartUpdateCallBack: any
) => {
  cartItems = cartItems.filter((cartItem: any) => {
    return cartItem.id !== item.id;
  });
  localStorage.cartItems = JSON.stringify(cartItems);
  cartUpdateCallBack({ items: cartItems});
};

const isCartItemInMenu = (menuItems: any, cartItem: any) => {
  return menuItems.some((menuItem: any) => {
    if (menuItem.id === cartItem.id) {
      return true;
    }
    if (menuItem.items?.length) {
      return menuItem.items.some((itemInGroup: any) => {
        return itemInGroup.id == cartItem.id;
      });
    }
    return false;
  });
};

const validateCartItems = (
  categories: any,
  cartItems: any,
  errorCallback: any
) => {
  let itemsInMenuCount = cartItems.reduce((count: number, cartItem: any) => {
    categories.some((category: any) => {
      if (isCartItemInMenu(category.items, cartItem)) {
        count++;
        return true;
      }
      return false;
    });
    return count;
  }, 0);
  if (itemsInMenuCount === cartItems.length) {
    return cartItems;
  } else {
    errorCallback();
    localStorage.cartItems = JSON.stringify([]);
    return [];
  }
};

const findItemsForReorder = (
  pastOrderItems: any,
  categories: any,
  errorCallback: any
) => {
  let filteredPastOrderItems =
    pastOrderItems.order_details.cartItems.filter((pastOrderItem: any) => {
      let categoriesWithPastOrderItems = categories.filter((category: any) => {
        return category.items.some((categoryItem: any) => {
          if (category.has_subcategory && categoryItem.items) {
            return categoryItem.items.some((subcategoryItem: any) => {
              return subcategoryItem.id === pastOrderItem.id         
            });
          } else {
            return categoryItem.id === pastOrderItem.id;
          }
        });
      });
      return categoriesWithPastOrderItems.length > 0;
    });
  if (filteredPastOrderItems.length === pastOrderItems.order_details.cartItems.length) {
    let payloadItems = pastOrderItems.order_details.items
    //removing promotion to prevent calculation discrepancies and expired promotions
    let promotionRemovedItems = removePromotions(
      filteredPastOrderItems,
      payloadItems
    );
    return promotionRemovedItems;
  } else {
    errorCallback();
    return [];
  }
}

const outOfStockItemsList = (cartItems: any, outOfStockItems: any) => {
  let unavailableItems:any = [];
  if (cartItems.length) {
    unavailableItems = cartItems.filter((item: any) =>
      isItemOutOfStock(item, outOfStockItems)
    );
  }
  return unavailableItems;
}
export {
  addItemToCart,
  updateItemWithCartIdInCart,
  updateItemWithIdInCart,
  removeItemWithCartId,
  removeItemWithId,
  validateCartItems,
  findItemsForReorder,
  addModifiedItemToCart,
  outOfStockItemsList,
};
