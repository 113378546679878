import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { calculateAmountsAndBreakdowns, formatDecimal } from "../../helpers/itemCalculations";
import { RootState } from "../../state/store";
import { useSelector } from "react-redux";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";

function CheckoutBreakdowns() {

  const { order_type: orderType } = useSelector(
    (state: RootState) => state.session
  );
  const { menu, discount, riderTip, loyalty } = useSelector(
    (state: RootState) => state
  );
  const { redeemedPoints } = useSelector((state: RootState) => state.loyalty);
  const { selectedArea } = useSelector((state: RootState) => state.areas);
  const { delivery_charge_id } = useSelector(
    (state: RootState) => state.outlet
  );
  const { currency } = useSelector((state: RootState) => state.company);

  const { appliedPromotionsData } = usePromotionsContext();

  const cartBreakdowns = useMemo(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      appliedPromotionsData.promotionAppliedCart,
      menu,
      orderType,
      discount || null,
      redeemedPoints || null,
      selectedArea || {},
      delivery_charge_id || null,
      riderTip || 0,
      appliedPromotionsData.orderPromotion
    );
    return amountBreakdowns;
  }, [
    appliedPromotionsData,
    menu,
    orderType,
    discount,
    redeemedPoints,
    selectedArea,
    delivery_charge_id,
    riderTip,
  ]);
  return (
    <div className="amount-details">
    <div className="amount subtotal">
      <p className="label">
        <FormattedMessage
          id="global.subtotal"
          defaultMessage="Subtotal"
        />
      </p>
      <p className="value">{formatDecimal(cartBreakdowns.subTotal)}</p>
    </div>
    {cartBreakdowns.surchargeBreakDown && cartBreakdowns.surchargeBreakDown.length
      ? cartBreakdowns.surchargeBreakDown.map((surcharge: any, index: number) => {
          return (
            <div className="amount charges" key={index}>
              <p className="label">
                <FormattedMessage
                  id="global.surcharge"
                  defaultMessage="Surcharge"
                />
              </p>
              <p className="value">
                {formatDecimal(surcharge.amount)}
              </p>
            </div>
          );
        })
      : false}
    {cartBreakdowns.orderPromotionDiscount > 0 && (
      <div className="amount discounts">
        <p className="label">{appliedPromotionsData.orderPromotion[0].name}</p>
        <p className="value">
          -{formatDecimal(cartBreakdowns.orderPromotionDiscount)}
        </p>
      </div>
    )}
    {discount && (
      <div className="amount discounts">
        <p className="label">{discount.discount_name}</p>
        <p className="value">
          -{formatDecimal(discount.discountedAmount)}
        </p>
      </div>
    )}
    {loyalty && loyalty.redeemedPoints ? (
      <div className="amount discounts">
        <p className="label">
          <FormattedMessage
            id="global.loyalty"
            defaultMessage="Loyalty"
          />
        </p>
        <p className="value">
          -{formatDecimal(loyalty.redeemedPoints)}
        </p>
      </div>
    ) : (
      false
    )}
    {!menu.is_tax_inclusive && cartBreakdowns.taxTotal > 0 ? (
      <div className="amount tax">
        <p className="label">
          <FormattedMessage
            id="global.tax"
            defaultMessage="Tax"
          />
        </p>
        <p className="value">{formatDecimal(cartBreakdowns.taxTotal)}</p>
      </div>
    ) : null}
    <div className="amount total">
      <p className="label">
        <FormattedMessage
          id="global.total"
          defaultMessage="Total"
        />
      </p>
      <p className="value">
        {currency} {formatDecimal(cartBreakdowns.totalBeforeTip)}
      </p>
    </div>
  </div>
  );
}

export default CheckoutBreakdowns;
