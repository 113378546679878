import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneNumberFieldMobile from './phoneNumberFieldMobile';
import EmailEmailFieldMobile  from './emailFieldMobile';


const PersonalDetailsMobile = () => {

  return (
    <div className="personal-details-mobile-wrapper">
      <p className="section-heading">
        <FormattedMessage
          id="my_account.personal_details"
          defaultMessage="Personal Details"
        />
      </p>

      <div className="personal-details-mobile">
        <PhoneNumberFieldMobile />
        <EmailEmailFieldMobile />
      </div>
    </div>
  );
};

export default PersonalDetailsMobile;
