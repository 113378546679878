// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { errorOperations } from "../../state/features/error";

interface IGeideaCardSectionWrapperProps {

  geideaKey: any;
  geideaFormData: any;
  totalAmount: any;
  placeOrder: any;
  updateError: any;
  togglePlaceOrderButton: any;
  updateCardToken: any;
  savedCards: any
  cardToken: any;
}

function GeideaCardSectionWrapper(props: IGeideaCardSectionWrapperProps) {
  useEffect(() => {
    if (props.savedCards.length > 0) {
      updateCardToken(props.savedCards[0]?.token);
    }
  }, [props.savedCards]);

  const updateCardToken = (token: any) => {
    props.updateCardToken(token);
  }

  const newCardCheckout = (event: any) => {
    //Configuring the payment gateway
    const geideaPayment = new GeideaApi(props.geideaKey, onSuccess, onError, onCancel);
    const amount = parseInt(props.geideaFormData.amount, 10);
    // Configure the gateway with all available details
    geideaPayment.configurePayment({
      amount: amount,
      currency: props.geideaFormData.currency,
      callbackUrl: props.geideaFormData.callback_url,
      merchantReferenceId: props.geideaFormData.ref_no,
      merchantLogoUrl: props.geideaFormData.logo_url,
      cardOnFile: true,
      initiatedBy: props.geideaFormData.initiated_by,
      agreementId: ""+Math.floor((Math.random() * 1000) + 0),
      agreementType: props.geideaFormData.agreement_type,
      language: props.geideaFormData.lang,
      paymentOperation: props.geideaFormData.payment_operation,
      styles: {
        headerColor: props.geideaFormData.brand_color,
        hppProfile: ""
      },
      address: {
        showAddress: true,
        billing: {
          country: "",
          city: "",
          street: "",
          postcode: ""
        },
        shipping: {
          country: "",
          city: "",
          street: "",
          postcode: ""
        }
      }
    });
     //call startPayment to open the gateway with all of the details configured earlier
    geideaPayment.startPayment();
  };

  const onSuccess = (response: any) =>{
    location.href = `/payment-processing?reference=${props.geideaFormData.ref_no}&type=geidea`;
  };

  const onError = (response: any) =>{
    props.updateError({
      titleCode: "error.oops",
      message: `${response.responseMessage}`,
    });
    props.togglePlaceOrderButton(true);
  };

  const onCancel = (response: any) =>{
    props.togglePlaceOrderButton(true);
  };

  return (
    <React.Fragment>
      {props.savedCards.length ? (
        <div className="payment-subsection">
          <div className="selections">
            {props.savedCards.map((savedCard: any, index: any) => {
              return(
                <ul key={index} >
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="geideaSavedCard"
                        onChange={() => updateCardToken(savedCard.token)}
                        checked={props.cardToken == savedCard.token}
                      />
                      {savedCard.card_no}
                    </label>
                  </li>
                </ul>
              )
            })}
            <ul>
              <li>
                <label>
                  <input
                    type="radio"
                    name="geideaNewCard"
                    onChange={() => updateCardToken(null)}                  
                    checked={!props.cardToken}
                  />
                  <FormattedMessage
                    id="payment.use_a_new_card"
                    defaultMessage="Use a new card"
                  />
                </label>
              </li>
            </ul>
            <div id="geideaCheckoutForm" hidden>
              <button type="submit" id="newCardCheckoutButton" className="hidden" onClick={newCardCheckout}>Checkout</button>
            </div>
          </div>
        </div>
      ) : (
        <div id="geideaCheckoutForm" hidden>
          <button type="submit" id="newCardCheckoutButton" className="hidden" onClick={newCardCheckout}>Checkout</button>
        </div>)}
  </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  let savedCards = state.user.metadata?.saved_cards?.geidea || [];

  return {
    savedCards
  };
};

const mapDispatchToProps = {
  updateError: errorOperations.updateError,
};

export default connect(mapStateToProps, mapDispatchToProps) (GeideaCardSectionWrapper);
