// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
// Components
import GeneralAnnouncementMobile from "../generalAnnouncementMobile";
import MarketingAnnouncementMobile from "../marketingAnnouncement";
import Loader from "../Loader";
import SideMenu from "../sideMenu";
import OrderTypeScreen from "../../mobileScreens/orderTypeScreen";
import ErrorPopupMobile from "../errorPopupMobile";
import OrderStatusBar from "../orderStatusBar";
import StoreCloseBanner from "../storeCloseBanner";
import AlertPopup from "../alertPopup";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faChevronLeft,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { userOperations } from "../../state/features/user";
import { sessionOperations } from "../../state/features/session";
// Helpers, Utils etc.
import { checkTint } from "../../helpers/utils";
import PaymentInProgressBanner from "../paymentInProgressBanner";
import PromotionsModalMobile from "../promotionModalMobile";
import AppliedPromotionsModalMobile from "../appliedPromotionsModal";

interface ITopHeaderProps {
  company: any;
  outlet: any;
  loader: any;
  header?: string;
  session: any;
  user: any;
  error: any;
  alert: any;
  handleBack?: any;
  setUser: any;
  setSession: any;
  order: any;
  categories: any;
  storeTimings: any;
  recentOrder: any;
  showPromotionModal:boolean;
}

function TopHeader(props: ITopHeaderProps) {
  const [showGeneralAnnouncement, setShowGeneralAnnouncement] = useState(false);
  const [showMarketingAnnouncement, setShowMarketingAnnouncement] = useState(
    false
  );
  const [showSideMenu, setShowMenu] = useState(false);
  const [tempOrderType, setTemporderType] = useState("Delivery");

  let history = useHistory();

  useEffect(() => {
    let historyListener = history.listen((location, action) => {
      if (action === "POP" && localStorage.getItem("otpStatus") === "mobile") {
        window.location.href = "/m/login";
        localStorage.removeItem("otpStatus");
      }
    });

    return () => historyListener();
  }, []);

  useEffect(() => {
    if (!props.header) {
      if (props.company.announcement) {
        setShowGeneralAnnouncement(true);
      }
      if (props.outlet.marketing_banner) {
        setShowMarketingAnnouncement(true);
      }
      if (props.company.brand_color) {
        let tint = checkTint(props.company.brand_color);
        let textColor = "#ffffff";
        let svgFilter = "brightness(0) invert(1)";
        let schemeColor = "dark";
        if (tint >= 170) {
          textColor = "#414952";
          svgFilter = "brightness(0) invert(0)";
          schemeColor = "light";
        }

        document.documentElement.style.setProperty("--text-color", textColor);

        document.documentElement.style.setProperty(
          "--primary-color",
          props.company.brand_color
        );

        document.documentElement.style.setProperty("--svg-filter", svgFilter);

        //iOS 15 update: Add theme color
        document.querySelector('meta[name="theme-color"]').setAttribute("content", props.company.brand_color);
        document.querySelector('meta[name="theme-color"]').setAttribute("media", `(prefers-color-scheme: ${schemeColor})`);
        if (props.company.announcement) {
          document.querySelector('meta[name="theme-color"]').setAttribute("content", "#313233");
        }
      }
      if (props.company.id) {
        updateHeaderHeight();
      }
    }
  }, [props.company]);

  useEffect(() => {
    if (props.session.order_type === "pickup") {
      setTemporderType("Pickup");
    } else {
      setTemporderType("Delivery");
    }
  }, [props.session]);

  useEffect(() => {
    if (props.session.showAreaSelect && document.getElementById("home")) {
      document.getElementById("home").style.display = "none";
      document.getElementsByClassName("header-action")[0].style.display = "none";
    }
  }, [props.session.showAreaSelect]);

  useEffect(() => {
    if (props.showPromotionModal) {
      setShowMenu(false);
      document.body.classList.remove("noscroll");
    }
  },[props.showPromotionModal])

  const handleShow = () => {
    if(!props.showPromotionModal) {
      setShowMenu(true);
      document.body.classList.add("noscroll");
    }
  };

  const updateHeaderHeight = () => {
    var logoSizeUpdater;
    clearTimeout(logoSizeUpdater);
    logoSizeUpdater = setTimeout(() => {
      var textLogoWidth = window.document.getElementById("company-name-temp")
        ?.offsetWidth;
      var svgLogo = window.document.getElementById("svglogo");

      svgLogo?.setAttribute("viewBox", "0 0 " + textLogoWidth + " 35");
    }, 1000);
  };

  const handleClose = () => {
    setShowMenu(false);
    document.body.classList.remove("noscroll");
  };

  const handleMarketingAnnouncementClose = () => {
    setShowMarketingAnnouncement(false);
  };

  const handleGeneralAnnouncementClose = () => {
    let headerHeight, statusBarHeight, inProgressStatusHeight;
    headerHeight = document.getElementById("header")?.clientHeight || 0;
    statusBarHeight = document.getElementById("status-bar")?.clientHeight || 0;
    inProgressStatusHeight =
      document.getElementById("in-progress-status")?.clientHeight || 0;
    let categoryBarPosition: any =
      headerHeight + 0 + statusBarHeight + inProgressStatusHeight;
    document.documentElement.style.setProperty(
      "--top-position",
      `${categoryBarPosition}px`
    );
    setShowGeneralAnnouncement(false);

    //for iOS 15: Reset the theme color to the default company brand color
    document.querySelector('meta[name="theme-color"]').setAttribute("content", props.company.brand_color);
  };

  const handleBack = () => {
    props.handleBack();
    document.body.classList.remove("noscroll");
    document.getElementsByClassName("header-action")[0].style.display = "";
    document.getElementById("home").style.display = "";
  };

  const hideAreaSelect = () => {
    let session = props.session as any;
    session.showAreaSelect = false;
    props.setSession(session);
    document.body.classList.remove("noscroll");
    document.getElementsByClassName("header-action")[0].style.display = "";
    document.getElementById("home").style.display = "";
  };

  const onOrderModeClick = () => {
    let session = props.session as any;
    session.showAreaSelect = true;
    props.setSession(session);
    document.getElementById("home").style.display = "none";
    document.getElementsByClassName("header-action")[0].style.display = "none";
  };

  return (
    <div
      id="top-header"
      style={{
        width: "100%",
        zIndex: 1051,
        left: 0,
      }}
    >
      <div id="fakeHeaderHeight" className="fakeHeaderHeight"></div>
      {props.loader.loading && <Loader message={props.loader.message} />}
      {props.storeTimings.open == false && !props.session.showAreaSelect ? (
        <StoreCloseBanner storeTimings={props.storeTimings} />
      ) : null}
      {location.pathname === "/m" &&
      showGeneralAnnouncement &&
      !props.session.showAreaSelect ? (
        <GeneralAnnouncementMobile
          announcement={props.company.announcement}
          onClose={handleGeneralAnnouncementClose}
        />
      ) : null}
      {location.pathname === "/m" &&
      showMarketingAnnouncement &&
      !props.session.showAreaSelect &&
      !props.error &&
      props.categories.length ? (
        <MarketingAnnouncementMobile
          announcement={props.outlet.marketing_banner}
          onClose={handleMarketingAnnouncementClose}
        />
      ) : null}
      {props.error && <ErrorPopupMobile />}
      {props.alert && <AlertPopup />}
      {props.recentOrder &&
      location.pathname === "/m" &&
      !props.session.showAreaSelect ? (
        <PaymentInProgressBanner />
      ) : null}
      {props.order.id &&
      location.pathname === "/m" &&
      !props.session.showAreaSelect ? (
        <OrderStatusBar />
      ) : (
        false
      )}
      <div className="top-header" id="header">
        <div className="header-action">
          {!props.header ? (
            showSideMenu ? (
              <FontAwesomeIcon
                className="menu-icon"
                onClick={handleClose}
                icon={faTimes}
              />
            ) : (
              <FontAwesomeIcon
                className="menu-icon"
                onClick={handleShow}
                icon={faBars}
              />
            )
          ) : (
            <FontAwesomeIcon
              className="menu-icon"
              onClick={handleBack}
              icon={faChevronLeft}
            />
          )}
        </div>
        <p className="res-name">
          <Link to="/m" className="res-link">
            <img src={props.company.logo} />
          </Link>
        </p>
        {!props.session.showAreaSelect && location.pathname === "/m" ? (
          <div className="order-mode" onClick={onOrderModeClick}>
            <FormattedMessage
              id={`global.${tempOrderType.toLowerCase()}`}
              defaultMessage={tempOrderType}
            />
            <span>
              <FontAwesomeIcon className="ml-1" icon={faCaretDown} />
            </span>
          </div>
        ) : null}
      </div>
      {showSideMenu && <SideMenu onClose={handleClose} />}
      {location.pathname === "/m" && props.session.showAreaSelect ? (
        <OrderTypeScreen
          onClose={() => hideAreaSelect()}
          mandatory={props.session.mandatory}
        />
      ) : null}
      <PromotionsModalMobile />
      <AppliedPromotionsModalMobile />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let loader = state.loader;
  let company = state.company;
  let outlet = state.outlet;
  let session = state.session;
  let user = state.user || {};
  let error = state.error;
  let order = state.orderInProgress || {};
  let categories = state.menu.categories || [];
  let storeTimings = state.storeTimings;
  let alert = state.alert;
  let recentOrder = state.recentOrder;
  let showPromotionModal = state.promotions.showPromotionsModal;

  return {
    company,
    outlet,
    loader,
    session,
    user,
    error,
    order,
    categories,
    storeTimings,
    alert,
    recentOrder,
    showPromotionModal,
  };
};

const mapDispatchToProps = {
  setUser: userOperations.setUser,
  setSession: sessionOperations.setSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
