// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import ModalHead from "../modalHead";
import ModalCloseIcon from "../modalCloseIcon";
import PostalCodeSelector from "../postalCodeSelector";
import GoogleMapAreaSearch from "../googleMapAreaSearch";
import SelectInput from "../selectInput";
import PickupAreaMap from "../pickupAreaMap";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { areasOperations } from "../../state/features/areas";
import { sessionOperations } from "../../state/features/session";

interface IAreaSelectPopupProps {
  onClose: () => void;
  mandatory?: boolean;
  orderType: string;
  handleTempOrderType: any;
  session: any;
  company: any;
  areas: any;
  zoneMapEnabled: boolean;
  fetchAreas: any;
  changeDeliveryArea: any;
  setSession: any;
  showOrderTypes: any;
}

function AreaSelectPopup(props: IAreaSelectPopupProps) {
  const [show, setShow] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState([] as any);
  const [pickupAreas, setPickupAreas] = useState([] as any);
  const [selectedDeliveryArea, changeSelectedDeliveryArea] = useState(
    {} as any
  );
  const [selectedPickupArea, changeSelectedPickupArea] = useState({} as any);
  const [selectedPickupMode, changeSelectedPickupMode] = useState("in_store");
  const [noAreaSelectedError, setNoAreaSelectedError] = useState("");

  useEffect(() => {
    props.fetchAreas().then((response: any) => {
      if (response.error) {
        handleClose();
      }
    });
  }, []);

  useEffect(() => {
    let delAreas = props.areas.delivery.map((area: any) => {
      area.label = area.name;
      area.value = area.id;
      return area;
    });
    setDeliveryAreas(delAreas);
    setPickupAreas(props.areas.pickup);
    let selectedOption;
    selectedOption = props.areas.pickup.find((area: any) => {
      return area.outlet_id == props.session.outlet_id;
    });
    selectedOption ? changeSelectedPickupArea(selectedOption) : false;

    if (!props.zoneMapEnabled) {
      selectedOption = delAreas.find((area: any) => {
        return area.id == props.session.area_id;
      });
      selectedOption ? changeSelectedDeliveryArea(selectedOption) : false;
    }

    if (props.areas.delivery.length || props.areas.pickup.length) {
      setShow(true);
    }
  }, [props.areas]);

  useEffect(() => {
    changeSelectedPickupMode(props.session.pickup_mode);
  }, [props.session.pickup_mode]);

  useEffect(() => {
    if (props.company.modes_of_pickup) {
      if (!props.company.modes_of_pickup.in_store) {
        changeSelectedPickupMode("curbside");
      }
      if (!props.company.modes_of_pickup.curbside) {
        changeSelectedPickupMode("in_store");
      }
    }
  }, [props.company.modes_of_pickup]);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose(), 1000;
    }, 500);
  };

  const handleDeliverySelection = (id: string, name: string = "") => {
    setNoAreaSelectedError("");
    let area = deliveryAreas.find((area: any) => {
      return area.id == id;
    });

    if (name) {
      area.name = name;
    }
    changeSelectedDeliveryArea(area);
  };

  const handlePickupSelection = (area: any) => {
    setNoAreaSelectedError("");
    changeSelectedPickupArea(area);
  };

  const handleDeliverySubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedDeliveryArea.id) {
      let session = props.session;
      session.area_id = selectedDeliveryArea.id;
      delete session.pickup_mode;
      props.setSession(session);
      changeDeliveryArea(selectedDeliveryArea, null);
    } else {
      setNoAreaSelectedError("delivery");
    }
  };

  const handlePickupSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedPickupArea.outlet_id) {
      let session = props.session;
      session.outlet_id = selectedPickupArea.outlet_id;
      session.pickup_mode = selectedPickupMode;
      delete session.area_id;
      props.setSession(session);
      changeDeliveryArea(selectedPickupArea, selectedPickupMode);
    } else {
      setNoAreaSelectedError("pickup");
    }
  };

  const changeDeliveryArea = (area: any, pickupMode: any) => {
    setShow(false);
    props.changeDeliveryArea(area, props.orderType, pickupMode)
    .then((response: any) => {
      if (!response.error) {
        if (response.payload.data.outlet_changed) {
          location.reload();
        }
      }
    });
  };

  const onPickupModeChange = (event: any) => {
    changeSelectedPickupMode(event.target.name);
  }

  const resetDeliveryAreaSelection = () => {
    changeSelectedDeliveryArea({});
  };

  const areaSelected = () => {
    return (
      (props.orderType === "delivery" && selectedDeliveryArea?.id) ||
      (props.orderType === "pickup" && selectedPickupArea?.id)
    );
  }

  return (
    <Modal
      show={show}
      dialogClassName="custom curved"
      onHide={handleClose}
      centered
      backdrop={props.mandatory ? "static" : true}
    >
      {props.mandatory ? false : <ModalCloseIcon onClick={handleClose} />}
      {props.company.modes_of_delivery != "pickup" &&
        props.orderType === "delivery" ? (
        <div className="area-select-popup">
          <h1 className="title-delivery">
            {props.zoneMapEnabled ? (
              <FormattedMessage
                id="area_selector.google_map.search_for_your_address"
                defaultMessage="Search for your address"
              />
            ) : (
              <FormattedMessage
                id="area_selector.area_dropdown.select_your_delivery_area"
                defaultMessage="Select your delivery area"
              />
            )}
          </h1>
          <form onSubmit={handleDeliverySubmission} className="delivery-select">
            {noAreaSelectedError ? (
              <div className="input-label text-center text-danger">
                {noAreaSelectedError == "address" ? (
                  <FormattedMessage
                    id="area_selector.google_map.address_not_serviceable"
                    defaultMessage="This address is not serviceable"
                  />
                ) : noAreaSelectedError == "delivery" ? (
                  props.zoneMapEnabled ? (
                    <FormattedMessage
                      id="area_selector.google_map.select_to_continue"
                      defaultMessage="Please enter your address to continue."
                    />
                  ) : (
                    <FormattedMessage
                      id="area_selector.area_dropdown.select_to_continue"
                      defaultMessage="Please select a delivery area to continue."
                    />
                  )
                ) : null}
              </div>
            ) : null}
            {props.zoneMapEnabled ? (
              <GoogleMapAreaSearch
                options={deliveryAreas}
                onChange={handleDeliverySelection}
                updateAreaError={(error: string) => setNoAreaSelectedError(error)}
                resetDeliveryAreaSelection={resetDeliveryAreaSelection}
              />
            ) : (
              <SelectInput
                fullWidth
                placeholder={
                  <FormattedMessage
                    id="area_selector.area_dropdown.enter_delivery_area_here"
                    defaultMessage="Enter delivery area here"
                  />
                }
                options={deliveryAreas}
                onChange={handleDeliverySelection}
                defaultValue={selectedDeliveryArea.id}
                autoFocus={true}
              />
            )}
            <button
              className="button btn btn-primary w-100 py-2 mt-4"
              type="submit"
              disabled={!areaSelected()}
            >
              <FormattedMessage
                id="global.next"
                defaultMessage="Next"
              />
              <span className="ml-3">
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </span>
            </button>
          </form>
          {props.mandatory &&
            props.company.modes_of_delivery == "delivery_and_pickup" ? (
            <p
              className="secondary-option"
              onClick={() => props.showOrderTypes()}
            >
              <FormattedMessage
                id="global.back"
                defaultMessage="Back"
              />
            </p>
          ) : props.company.modes_of_delivery != "delivery" ? (
            <p
              className="secondary-option"
              onClick={() => props.handleTempOrderType("pickup")}
            >
              <FormattedMessage
                id="pickup_selector.pickup_from_restaurant"
                defaultMessage="Pickup From Restaurant"
              />
            </p>
          ): null}
        </div>
      ) : (
        <form
          className="area-select-popup pickup"
          onSubmit={handlePickupSubmission}
        >
          <h1 className="title-pickup">
            <FormattedMessage
              id="pickup_selector.where_would_you_like_to_collect_your_order"
              defaultMessage="Where would you like to collect your order?"
            />
          </h1>
          {noAreaSelectedError == "pickup" ? (
            <div className="input-label text-center text-danger">
              <FormattedMessage
                id="pickup_selector.select_to_continue"
                defaultMessage="Please select a pickup location to continue."
              />
            </div>
          ) : null}
          <div className="area-section">
            <div className="map">
              <PickupAreaMap
                lat={selectedPickupArea.latitude}
                lng={selectedPickupArea.longitude}
              />
            </div>
            <div className="area-list">
              {pickupAreas.map((area: any, index: any) => {
                return (
                  <div
                    className={
                      selectedPickupArea.outlet_id === area.outlet_id
                        ? "area-input selected"
                        : "area-input"
                    }
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`${area.id}`}
                      onChange={() => handlePickupSelection(area)}
                      value="option2"
                      checked={area.outlet_id === selectedPickupArea.outlet_id}
                    />
                    <label
                      className="form-check-label w-100 area-label"
                      htmlFor={`${area.id}`}
                    >
                      <p className="area-name">{area.name}</p>
                      <p className="area-address">{area.address}</p>
                      {area.outlet_id === selectedPickupArea.outlet_id && (
                        props.company.modes_of_pickup?.in_store ||
                        props.company.modes_of_pickup?.curbside
                      ) ? (
                        <div className="pickup-methods">
                          <label>
                            <FormattedMessage
                              id="pickup_selector.select_your_preferred_pickup_method"
                              defaultMessage="Select your preferred pickup method"
                            />:
                          </label>
                          <div className="methods">
                            {props.company.modes_of_pickup.in_store ? (
                              <div>
                                <input
                                  type="radio"
                                  id="in_store"
                                  name="in_store"
                                  checked={
                                    !selectedPickupMode ||
                                    selectedPickupMode == "in_store"
                                  }
                                  onChange={onPickupModeChange}
                                />
                                <label htmlFor="in_store">
                                  <FormattedMessage
                                    id="pickup_selector.in_store"
                                    defaultMessage="In-Store"
                                  />
                                </label>
                              </div>
                            ) : null}
                            {props.company.modes_of_pickup.curbside ? (
                              <div>
                                <input
                                  type="radio"
                                  id="curbside"
                                  name="curbside"
                                  checked={selectedPickupMode == "curbside"}
                                  onChange={onPickupModeChange}
                                />
                                <label htmlFor="curbside">
                                  <FormattedMessage
                                    id="pickup_selector.curbside"
                                    defaultMessage="Curbside"
                                  />
                                </label>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pickup-action">
            <button
              className="button btn btn-primary w-100 py-2 mt-4"
              type="submit"
            >
              <FormattedMessage
                id="global.next"
                defaultMessage="Next"
              />
              <span className="ml-3">
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </span>
            </button>

            {props.mandatory &&
              props.company.modes_of_delivery == "delivery_and_pickup" ? (
              <p
                className="pickupButtonCancel"
                onClick={() => props.showOrderTypes()}
              >
                <FormattedMessage
                  id="global.back"
                  defaultMessage="Back"
                />
              </p>
            ) : props.company.modes_of_delivery != "pickup" ? (
              <p
                type="button"
                className="pickupButtonCancel"
                onClick={() => props.handleTempOrderType("delivery")}
              >
                <FormattedMessage
                  id="global.cancel"
                  defaultMessage="Cancel"
                />
              </p>
            ): null}
          </div>
        </form>
      )}
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let session = state.session;
  let areas = state.areas;
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;

  return {
    company,
    session,
    areas,
    zoneMapEnabled,
  };
};

const mapDispatchToProps = {
  fetchAreas: areasOperations.fetchAreas,
  changeDeliveryArea: sessionOperations.changeDeliveryArea,
  setSession: sessionOperations.setSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelectPopup);
