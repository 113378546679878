// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import MobileInlineLoader from "../mobileInlineLoader";
import ScheduleOrdersMobile from "../scheduleOrderMobile";
import NewAddressWidgetMobile from "../newAddressWidgetMobile";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMotorcycle } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { addressesOperations } from "../../state/features/addresses";
import { errorOperations } from "../../state/features/error";
import { cartOperations } from "../../state/features/cart";
import { areasOperations } from "../../state/features/areas";
// Helpers, Utils etc.
import { validateCartItems } from "../../helpers/cartFunctions";
import { calculateAmountsAndBreakdowns } from "../../helpers/itemCalculations";
import { obtainEtaDisplayText } from "../../helpers/utils";

interface IAddressDetailsProps {
  orderType: any;
  addresses: any;
  fetchCheckoutConfigurations: any;
  outlet: any;
  session: any;
  fetchAddresses: any;
  company: any;
  menu: any;
  discount: any;
  redeemedPoints: any;
  modesOfPickup: any;
  pickupMode: any;
  pickupModeFromSession: any;
  orderNotes: any;
  metaNotes: any;
  curbsideNotes: any;
  area: any;
  displayEta: any;
  userName: string;
  userEmail: string;
  user: any;
  zoneMapEnabled: boolean;
  minimumEtaMinutes: any;
  riderTip: any;
  updateError: any;
  onAddressSelect: any;
  setCart: any;
  changePickupMode: any;
  updateOrderNotes: any;
  updateMetaNotes: any;
  updateCurbsideNotes: any;
  updateScheduleTime: any;
  updateEtas: any;
  setArea: any;
  updateUserName: any;
  updateUserEmail: any;
  updateMinimumOrderEta: any;
  appliedOrderPromotion: any[];
  promotionAppliedCart: any[];
}

interface IAddressDetailsState {
  addressLoading: boolean;
  selectedAddress: any;
  showAddAddress: boolean;
  showScheduleOrderPopup: boolean;
  hasLeadTime: boolean;
  defaultDeliveryTime: any;
  usableAddresses: any;
}

const SCHEDULE_BUFFER_TIME_IN_MINS = 15;

class AddressDetails extends Component<
  IAddressDetailsProps,
  IAddressDetailsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      addressLoading: true,
      selectedAddress: {},
      showAddAddress: false,
      showScheduleOrderPopup: false,
      defaultDeliveryTime: null,
      hasLeadTime: false,
      usableAddresses: [],
    };
    this.props.fetchCheckoutConfigurations().then((response: any) => {
      if (!response.error) {
        this.updateItemsInCart(
          validateCartItems(
            this.props.menu.categories,
            JSON.parse(localStorage.cartItems || "[]"),
            this.itemsNotInMenuError
          )
        );
        localStorage.precision = this.props.company.decimal_precision;
        if (this.props.orderType == "pickup") {
          this.findLocationAddress();
        } else {
          this.fetchAddresses();
        }
        this.fetchAddresses();
        this.findMinimumEtaAndLeadTime();
        this.findSelectedModeOfPickup();
      }
    });
  }

  updateItemsInCart(cartItems: any) {
    let cart = calculateAmountsAndBreakdowns(
      this.props.promotionAppliedCart,
      this.props.menu,
      this.props.orderType,
      this.props.discount,
      this.props.redeemedPoints,
      this.props.area,
      this.props.outlet.delivery_charge_id,
      this.props.riderTip,
      this.props.appliedOrderPromotion,
    ) as any;
    cart.items = cartItems;

    this.props.setCart(cart);
    if (!cartItems?.length) {
      this.props.updateError({
        title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
        message: this.props.intl.formatMessage({ id: "error.cart_is_empty", defaultMessage: "Your cart is empty! Please add items from the menu." }),
        redirectionUrl: "/m",
      });
    }
  }

  itemsNotInMenuError = () => {
    this.props.updateError({
      title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
      message: this.props.intl.formatMessage({ id: "error.items_in_cart_not_in_menu", defaultMessage: "Items in cart are not part of current available menu." }),
    });
  }

  fetchAddresses() {
    this.setState(
      {
        showAddAddress: false,
        addressLoading: true,
      },
      () => {
        this.props.fetchAddresses().then(() => {
          this.filterUsableAndNonUsableAddresses();
        });
      }
    );
  }

  findLocationAddress() {
    let selectedAddress = {
      lines: [{ id: 1, value: this.props.area?.address }],
    };
    this.props.onAddressSelect(selectedAddress);
  }

  filterUsableAndNonUsableAddresses() {
    let usableAddresses = [] as any;
    let addresses = this.props.addresses;
    addresses.forEach((address: any) => {
      if (address.sapaad_delivery_area_id == this.props.area.id) {
        usableAddresses.push(address);
      }
    });
    if (usableAddresses.length) {
      this.selectAddress(usableAddresses[0], true);
    }
    this.setState({
      addressLoading: false,
      usableAddresses,
    });
  }

  findMinimumEtaAndLeadTime() {
    let minimumEtaMinutes;
    let leadTime = Number(this.props.company.lead_time || 0);
    let defaultEta = Number(this.props.company.default_eta);
    let hasLeadTime = leadTime > 0;

    // Minimum time in minutes to deliver order is
    // Lead time + Default ETA (ie. Preparation + Delivery Time)
    if (hasLeadTime) {
      minimumEtaMinutes = leadTime + defaultEta;
    } else {
      minimumEtaMinutes = defaultEta;
    }
    this.props.updateMinimumOrderEta(minimumEtaMinutes);
    this.setState({ hasLeadTime }, () =>
      this.findExpectedDeliveryTime(null, null, true)
    );
  }

  findExpectedDeliveryTime(
    expectedDate: any,
    expectedTime: any,
    isDefault: boolean
  ) {
    let expectedDeliveryTime, displayEta;
    let minimumEtaMinutes = this.props.minimumEtaMinutes;
    let hasLeadTime = this.state.hasLeadTime;
    let defaultEta = Number(this.props.company.default_eta);

    let currentTime = new Date();
    let currentDateString = currentTime.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    let expectedMinutes = Number(currentTime.getMinutes()) + minimumEtaMinutes;
    currentTime.setMinutes(expectedMinutes);

    if (isDefault) {
      expectedDeliveryTime = currentTime;
      this.setState({ defaultDeliveryTime: currentTime });
    } else {
      let convertedTime = this.convertTo24Hours(expectedTime);
      let convertedDate = expectedDate.toLocaleString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      expectedDeliveryTime = new Date(convertedDate + " " + convertedTime);
    }

    let scheduledTime = new Date(expectedDeliveryTime.getTime());
    if (hasLeadTime || expectedDeliveryTime.getTime() > currentTime.getTime()) {
      scheduledTime.setMinutes(scheduledTime.getMinutes() - defaultEta);
      // Including buffer time
      let scheduledTimeWithBuffer = new Date(scheduledTime.getTime());
      scheduledTimeWithBuffer.setMinutes(
        scheduledTime.getMinutes() - SCHEDULE_BUFFER_TIME_IN_MINS
      );
      if (scheduledTimeWithBuffer.getTime() >= new Date().getTime()) {
        scheduledTime = scheduledTimeWithBuffer;
      }
      this.props.updateScheduleTime(scheduledTime);
    } else {
      expectedDeliveryTime = currentTime;
      this.props.updateScheduleTime(null);
    }

    displayEta = obtainEtaDisplayText(expectedDeliveryTime, this.props.intl.formatMessage);
    this.props.updateEtas(expectedDeliveryTime, displayEta);
  }

  findSelectedModeOfPickup() {
    if (
      this.props.orderType == "pickup" &&
      (this.props.modesOfPickup?.in_store || this.props.modesOfPickup?.curbside)
    ) {
      this.props.changePickupMode(
        this.props.pickupModeFromSession || "in_store"
      );
    }
  }

  onPickupModeChange(event: any) {
    let metaNotes = this.props.metaNotes;
    let curbsideNotes = this.props.curbsideNotes;
    if (event.target.name != "curbside") {
      metaNotes = "";
      curbsideNotes = {};
    }
    this.props.changePickupMode(event.target.name);
    this.props.updateMetaNotes(metaNotes);
    this.props.updateCurbsideNotes(curbsideNotes);
  }

  confirmScheduleDate(date: any, time: any) {
    this.findExpectedDeliveryTime(date, time, false);
  }

  convertTo24Hours(time12h: any) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.session.showAreaSelect !== prevProps.session.showAreaSelect) {
      this.verifyCurrentSession();
    }
    if (this.props.company !== prevProps.company) {
      this.findDeliveryArea();
    }
  }

  verifyCurrentSession() {
    if (this.props.session.showAreaSelect) {
      this.props.updateError({
        titleCode: "error.oops",
        messageCode: "error.unable_to_process_order",
        redirectionUrl: "/m",
      });
    }
  }

  findDeliveryArea() {
    let area;
    if (this.props.orderType == "pickup") {
      area = {
        name: this.props.outlet.name,
        address: this.props.outlet.address,
      };
    } else {
      area = this.props.outlet.delivery_areas.find((area: any) => {
        return area.id == this.props.session.area_id;
      }) || {};
      if (this.props.zoneMapEnabled && this.props.session.area_name) {
        area.name = this.props.session.area_name;
      }
    }
    this.props.setArea(area);
  }

  handleAddAddress() {
    this.setState({ showAddAddress: true });
    document.body.classList.add("noscroll");
  }

  cancelAddAddress() {
    document.body.classList.remove("noscroll");
    this.setState({ showAddAddress: false });
  }

  selectAddress(address: any, addressUsable: boolean) {
    if (!addressUsable) {
      return;
    }
    let selectedAddress = {
      id: address.address_id,
      lines: address.delivery_addresses,
      area_id: address.sapaad_delivery_area_id,
      outlet_id: address.sapaad_location_id,
      latitude: address.latitude,
      longitude: address.longitude,
    };
    this.setState({ selectedAddress: selectedAddress }, () =>
      this.props.onAddressSelect(selectedAddress)
    );
  }

  handleOrderNotes(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.props.updateOrderNotes(e.target.value);
  }

  updateCurbsideNotes(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    let curbsideNotes = this.props.curbsideNotes;
    curbsideNotes[e.target.name] = e.target.value;

    let metaNotes = [curbsideNotes.car_model, curbsideNotes.car_color, curbsideNotes.car_plate_number].join(', ');

    this.updateInputValidationMessage("car_model", "");
    this.updateInputValidationMessage("car_color", "");
    this.updateInputValidationMessage("car_plate_number", "");
    this.props.updateCurbsideNotes(curbsideNotes);
    this.props.updateMetaNotes(metaNotes);
  }

  updateInputValidationMessage(inputFieldID: string, message: string) {
    document.getElementById(inputFieldID)?.setCustomValidity(message);
  }

  onScheduleOrderClick() {
    this.setState({ showScheduleOrderPopup: true });
  }

  handleScheduleOrderClose() {
    this.setState({ showScheduleOrderPopup: false });
  }

  handleAddNewButton() {
    document.body.classList.add("noscroll");
    this.setState({ showAddAddress: true });
  }

  render() {
    const { orderType, modesOfPickup, pickupMode } = this.props;
    const { usableAddresses } = this.state;
    return (
      <div>
        <div className="form-group mb-4 ">
          <label className="input-label bold mb-0 delivery-type-label">
            <FormattedMessage
              id="checkout.your_name"
              defaultMessage="Your Name"
            />: {this.props.user.name ? (
              <span className="areaName">{this.props.user.name}</span>
            ) : false}
          </label>
          {!this.props.user.name ? (
            <FormattedMessage
              id="checkout.enter_your_name_here"
              defaultMessage="Enter your name here..."
            >
              {(placeholder) => (
                <input
                  id="user_name"
                  name="user_name"
                  type="text"
                  placeholder={placeholder}
                  required
                  className="form-control custom-input"
                  value={this.props.userName}
                  onChange={(event: any) =>
                    this.props.updateUserName(event.target.value)
                  }
                ></input>
              )}
            </FormattedMessage>
          ) : null}
        </div>
        <div className="form-group mb-4 ">
          <label className="input-label bold mb-0 delivery-type-label">
            <span>
              {orderType === "pickup" ? (
                <FormattedMessage
                  id="header.pickup_from"
                  defaultMessage="Pickup from"
                />
              ) : (
                <FormattedMessage
                  id="header.delivering_to"
                  defaultMessage="Delivering to"
                />
              )}{": "}
            </span>
            <span className="areaName">{this.props.area?.name}</span>
          </label>
          {orderType === "pickup" &&
          (modesOfPickup?.in_store || modesOfPickup?.curbside) ? (
            <div className="delivery-address-details mt-4">
              <label className="input-label bold mb-2">
                <FormattedMessage
                  id="checkout.pickup_method"
                  defaultMessage="Pickup Method"
                />:
              </label>
              <div className="nested-section mb-4">
                <div className="pickup-methods">
                  <form>
                    {modesOfPickup.in_store ? (
                      <div className="form-check method">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="in_store"
                            name="in_store"
                            checked={!pickupMode || pickupMode == "in_store"}
                            onChange={(event: any) => {
                              this.onPickupModeChange(event);
                            }}
                          />
                          <span></span>
                          <FormattedMessage
                            id="pickup_selector.in_store"
                            defaultMessage="In-Store"
                          />
                        </label>
                      </div>
                    ) : null}
                    {modesOfPickup.curbside ? (
                      <div className="form-check method">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="curbside"
                            name="curbside"
                            checked={pickupMode == "curbside"}
                            onChange={(event: any) => {
                              this.onPickupModeChange(event);
                            }}
                          />
                          <span></span>
                          <FormattedMessage
                            id="pickup_selector.curbside"
                            defaultMessage="Curbside"
                          />
                        </label>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
              {pickupMode == "curbside" ? (
                <div className="form-group mb-4">
                  <label className="input-label bold mb-2">
                    <FormattedMessage
                      id="checkout.curbside_details"
                      defaultMessage="Curbside Details"
                    />:
                  </label>
                  <div className="nested-section">
                    <div className="pickup-details">
                      <div className="form-group mb-4 ">
                        <label className="input-label bold mb-0">
                          <FormattedMessage
                            id="checkout.car_model"
                            defaultMessage="Car Model"
                          />
                        </label>
                        <div className="form-group mb-4">
                          <FormattedMessage
                            id="checkout.car_model_example"
                            defaultMessage="eg. Nissan Kicks"
                          >
                            {(placeholder) => (
                              <input
                                id="car_model"
                                name="car_model"
                                type="text"
                                placeholder={placeholder}
                                required
                                className="form-control custom-input mt-2"
                                value={this.props.metaNotes?.car_model}
                                onChange={(event: any) =>
                                  this.updateCurbsideNotes(event)
                                }
                              ></input>
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="form-group mb-4 ">
                        <label className="input-label bold mb-0">
                          <FormattedMessage
                            id="checkout.car_color"
                            defaultMessage="Car Color"
                          />
                        </label>
                        <div className="form-group mb-4">
                          <FormattedMessage
                            id="checkout.car_color_example"
                            defaultMessage="eg. Red"
                          >
                            {(placeholder) => (
                              <input
                                id="car_color"
                                name="car_color"
                                type="text"
                                placeholder={placeholder}
                                required
                                className="form-control custom-input mt-2"
                                value={this.props.metaNotes?.car_color}
                                onChange={(event: any) =>
                                  this.updateCurbsideNotes(event)
                                }
                              ></input>
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="form-group mb-4 ">
                        <label className="input-label bold mb-0">
                          <FormattedMessage
                            id="checkout.car_plate_number"
                            defaultMessage="Plate Number"
                          />
                        </label>
                        <div className="form-group mb-4">
                          <FormattedMessage
                            id="checkout.car_plate_number_example"
                            defaultMessage="eg. V 12345"
                          >
                            {(placeholder) => (
                              <input
                                id="car_plate_number"
                                name="car_plate_number"
                                type="text"
                                placeholder={placeholder}
                                required
                                className="form-control custom-input mt-2"
                                value={this.props.metaNotes?.car_plate_number}
                                onChange={(event: any) =>
                                  this.updateCurbsideNotes(event)
                                }
                              ></input>
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : orderType === "delivery" ? (
            <div className="delivery-address-details mt-4">
              <div>
                <label className="input-label bold mb-2">
                  <FormattedMessage
                    id="address.delivery_address"
                    defaultMessage="Delivery Address"
                  />:
                </label>
                {this.state.addressLoading ? (
                  <MobileInlineLoader />
                ) : (
                  <div
                    className={
                      !this.state.showAddAddress
                        ? "savedAddress shown"
                        : "savedAddress hidden"
                    }
                  >

                    {usableAddresses.length ? (
                      usableAddresses.map((address: any) => {
                        const addressLines = address.delivery_addresses;
                        const area = addressLines[1].value
                          ? `, ${addressLines[1].value}`
                          : "";
                        const landMark = addressLines[3].value
                          ? `, ${addressLines[3].value}`
                          : "";
                        return (
                          <div
                            key={address.address_id}
                            className={
                              address.address_id ==
                              this.state.selectedAddress.id
                                ? "address-widget selected"
                                : "address-widget"
                            }
                            onClick={() => this.selectAddress(address, true)}
                          >
                            <div className="area-details">
                              <p className="area font-weight-bold">
                                {addressLines[0].value + area}
                              </p>
                              <p className="street">
                                {addressLines[2].value + landMark}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      false
                    )}

                    {usableAddresses.length && !this.state.showAddAddress ? (
                      <p
                        className="add-address-mobile"
                        onClick={() => this.handleAddAddress()}
                      >
                        <FormattedMessage
                          id="address.add_an_address"
                          defaultMessage="Add an Address"
                        />
                      </p>
                    ) : (
                      false
                    )}
                  </div>
                )}
              </div>
              {(this.state.showAddAddress || !usableAddresses.length)  ? (
                <NewAddressWidgetMobile
                  area={this.props.area}
                  reloadAddresses={() => this.fetchAddresses()}
                  cancelNewAddress={() => this.cancelAddAddress()}
                />
              ) : (
                false
              )}
              {!this.state.addressLoading && !usableAddresses.length ? (
                <div className="address-widget selected no-address">
                  <p
                    onClick={() => this.handleAddNewButton()}
                    className="add-address-mobile"
                  >
                    <FormattedMessage
                      id="address.add_an_address"
                      defaultMessage="Add an Address"
                    />
                  </p>
                </div>
              ) : (
                false
              )}
            </div>
          ) : null}
        </div>
        <div className="form-group mb-4">
          <label className="input-label bold mb-2">
            {orderType === "pickup" ? (
              <FormattedMessage
                id="checkout.expected_pickup_time"
                defaultMessage="Expected Pickup Time"
              />
            ) : (
              <FormattedMessage
                id="checkout.expected_delivery_time"
                defaultMessage="Expected Delivery Time"
              />
            )}{": "}
          </label>
          <div className="expected-delivery-label">
            <div className="expected-delivery-display">
              <span>
                {this.props.company.scheduled_order_enabled ? (
                  <React.Fragment>
                    <span className="change-schedule">
                      {this.props.displayEta}
                    </span>
                    &nbsp;
                    <a
                      onClick={() => this.onScheduleOrderClick()}
                      className="add-new-address-mobile"
                    >
                      <FormattedMessage
                        id="checkout.schedule_for_later"
                        defaultMessage="Schedule for later"
                      />
                    </a>
                  </React.Fragment>
                ) : (
                  <span>{this.props.displayEta}</span>
                )}
                {this.state.showScheduleOrderPopup && (
                  <ScheduleOrdersMobile
                    onClose={() => this.handleScheduleOrderClose()}
                    for={orderType === "pickup" ? "Pickup" : "Delivery"}
                    defaultEta={this.props.company.default_eta}
                    openingHours={this.props.outlet.opening_hours}
                    defaultDeliveryTime={this.state.defaultDeliveryTime}
                    confirmScheduleDate={(date: any, time: any) =>
                      this.confirmScheduleDate(date, time)
                    }
                    minimumEtaMinutes={this.props.minimumEtaMinutes}
                    hasLeadTime={this.state.hasLeadTime}
                    convertTo24Hours={
                      (timeToConvert: any) => this.convertTo24Hours(timeToConvert)
                    }
                  />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group mb-4">
          <label className="input-label bold mb-2">
            <FormattedMessage
              id="checkout.order_notes_optional"
              defaultMessage="Order Notes <spanTag>(Optional)</spanTag>"
              values={{
                spanTag: (text) => <span>{text}</span>
              }}
            />
          </label>
          <FormattedMessage
            id={
              orderType === "delivery"
              ? "checkout.any_special_request_on_the_preparation_or_delivery_of_your_food"
              : "checkout.any_special_request_on_the_preparation"
            }
            defaultMessage={
              orderType === "delivery"
              ? "Any special requests on the preparation or delivery of your food?"
              : "Any special requests on the preparation?"
            }
          >
            {(placeholder) => (
              <textarea
                name="delivery-notes"
                required
                placeholder={placeholder}
                className="form-control custom-input textarea"
                value={this.props.orderNotes}
                onChange={(event: any) => this.handleOrderNotes(event)}
              ></textarea>
            )}
          </FormattedMessage>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let orderType = state.session.order_type;
  let addresses = state.addresses;
  let outlet = state.outlet;
  let session = state.session;
  let company = state.company;
  let menu = state.menu;
  let discount = state.discount;
  let redeemedPoints = state.loyalty?.redeemedPoints;
  let modesOfPickup = state.company.modes_of_pickup;
  let pickupModeFromSession = state.session.pickup_mode;
  let area = state.areas.selectedArea;
  let user = state.user;
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;
  let riderTip = state.riderTip;
  let appliedOrderPromotion = state.promotions.appliedOrderPromotion;
  let promotionAppliedCart = state.promotions.promotionAppliedCart;

  return {
    orderType,
    addresses,
    outlet,
    session,
    company,
    menu,
    discount,
    redeemedPoints,
    modesOfPickup,
    pickupModeFromSession,
    area,
    user,
    zoneMapEnabled,
    riderTip,
    appliedOrderPromotion,
    promotionAppliedCart,
  };
};

const mapDispatchToProps = {
  fetchCheckoutConfigurations: pageOperations.fetchCheckoutConfigurations,
  fetchAddresses: addressesOperations.fetchAddresses,
  updateError: errorOperations.updateError,
  setCart: cartOperations.setCart,
  setArea: areasOperations.setArea,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddressDetails));
