import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { Modal } from "react-bootstrap";
import { promotionsOperations } from "../../state/features/promotions";
import CloseButton from "../../images/closeButton.svg";
import { FormattedMessage } from "react-intl";
import PromotionDetailItem from "../promotionItemMobile";

const PromotionsModalMobile = () => {
  const { showPromotionsModal, availablePromotions } = useSelector(
    (state: RootState) => state.promotions
  );
  const dispatch = useDispatch();

  const onModalClose = () => {
    dispatch(promotionsOperations.toggleShowPromotionModal());
  };

  return (
    <Modal
      centered
      show={showPromotionsModal}
      className="promotion-modal-mobile"
    >
      <div className="promotion-modal-content">
        <div className="d-flex justify-content-between align-items-center">
          <span className="modal-header-section">
            <FormattedMessage
              id="promotions.available_promotions"
              defaultMessage="Available Promotions"
            />
          </span>
          <span className="close-button-wrapper" onClick={onModalClose}>
            <img src={CloseButton} alt="Close" />
          </span>
        </div>
        <div className="promotion-list">
          {availablePromotions.map((promotion: any) => {
            return (
              <div key={promotion.id}>
                <PromotionDetailItem promotion={promotion} />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default PromotionsModalMobile;
