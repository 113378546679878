import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import { RootState } from "../../state/store";
// Redux Operations
import { riderTipOperations } from "../../state/features/riderTip";

interface IRiderTipsWidgetMobile {
  riderName: string;
  subTotal: number;
  addRiderTip: (tipAmount: number) => void;
  removeRiderTip: () => void;
}

const RiderTipsWidgetMobile = (props: IRiderTipsWidgetMobile) => {
  const [tipValues, setTipValues] = useState<any[]>([]);
  const [showCustomTipSection, setShowCustomTipSection] = useState(false);
  const [customTipValue, setCustomTipValue] = useState<string | boolean>("");
  const [selectedTip, setSelectedTip] = useState<any>({});
  const { tips, riderTip } = useSelector((state: RootState) => state);
  const { currency } = useSelector((state: RootState) => state.company);

  useEffect(() => {
    if (Object.keys(tips).length > 0) {
      let tipsArray = tips.values.map((tip: any, index: number) => {  
          return { type: tips.type, value: tip };
        })
        setTipValues(tipsArray);
    }
  }, [tips]);

  useEffect(() => {
    if(!riderTip) {
      setCustomTipValue("");
      setSelectedTip({});
      setShowCustomTipSection(false);
    }
  }, [riderTip]);

  const setCustomTip = (e: any) => {
    let regExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (!e.target.value) {
      setCustomTipValue("");
      return;
    } else if (!regExp.test(e.target.value)) {
      return;
    }
    setCustomTipValue(e.target.value);
  };

  const selectCustomTip = () => {
    if (customTipValue) {
      let tipValue = customTipValue;
      setSelectedTip({
        type: "value",
        value: parseFloat(formatDecimal(tipValue)),
      });
      props.addRiderTip(formatDecimal(customTipValue));
    }
  };

  const selectTip = () => {
    if (Object.keys(selectedTip).length > 0) {
      if (selectedTip.type === "percentage") {
        props.addRiderTip(
          formatDecimal((selectedTip.value / 100) * props.subTotal)
        );
      } else {
        props.addRiderTip(formatDecimal(selectedTip.value));
      } 
    }
  };

  const clearCustomTipInput = () => {
    setCustomTipValue("");
    setSelectedTip({});
    setShowCustomTipSection(false);
    props.removeRiderTip();
  };

  const removeRiderTip = () => {
    setSelectedTip({});
    setShowCustomTipSection(false);
    props.removeRiderTip();
  }

  const renderTipsTile = (tip: any) => {
    if (tip.type === "percentage") {
      return (
        <div>
          <span className="tips-main-text">{tip.value + "%"}</span>
          <br/>
          <span className="tips-sub-text">
            {formatDecimal((tip.value / 100) * props.subTotal)} {currency}
          </span>
        </div>
      );
    } else {
      return (
        <div>
            <span className="tips-main-text">{tip.value + ' ' + currency} </span>
        </div>
      );
    }
  }

  return (
    <div className="rider-tips-container">
      <div className="title">
        Make your rider’s day by leaving a tip!
      </div>
      {showCustomTipSection ? (
        <React.Fragment>
          <div className="section tips-custom">
            <div className="input-section-wrapper">
              <p className="currency">{currency}</p>
              <input
                type="text"
                value={customTipValue}
                className="custom-tip-input"
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCustomTip(e)
                }
              />
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="clear-tip"
                onClick={clearCustomTipInput}
              />
            </div>
          </div>
          <button className="btn btn-primary" onClick={selectCustomTip}>
            SUBMIT
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="section tips-config">
            <div className="section tips-container">
              {tipValues.map((tip, index: number) => (
                <div
                  key={index}
                  className={`tip-chip preset ${
                    selectedTip === tip ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTip(tip)}>
                  {renderTipsTile(tip)}
                </div>
              ))}
              <div
                className="tip-chip custom"
                onClick={() => setShowCustomTipSection(true)}>
                Custom
              </div>
            </div>
          </div>
          <button className="btn btn-primary" onClick={selectTip}>
            SUBMIT
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  addRiderTip: riderTipOperations.addRiderTip,
  removeRiderTip: riderTipOperations.removeRiderTip,
};

export default connect(null, mapDispatchToProps)(RiderTipsWidgetMobile);
