import * as types from "./types";

const toggleShowPromotionModal = () => ({
  type: types.TOGGLE_PROMOTION_MODAL,
})

const updatedAppliedPromotion = (promotions:any) => ({
  type: types.UPDATE_APPLIED_PROMOTIONS,
  payload: promotions,
});

const updatePromotionAppliedCart = (updatedCart:any[]) => ({
  type: types.UPDATE_PROMOTION_APPLIED_CART,
  payload: updatedCart,
});

const updateAppliedOrderPromotions = (promotions:any) => ({
  type: types.UPDATE_APPLIED_ORDER_PROMOTIONS,
  payload: promotions,
});

export {
  toggleShowPromotionModal,
  updatedAppliedPromotion,
  updatePromotionAppliedCart,
  updateAppliedOrderPromotions,
}
