import React from 'react';

interface IPromotionDetailItemProps {
  promotion:any
}
const PromotionDetailItem = ({promotion}:IPromotionDetailItemProps) => {
  return (
    <>
      <div className="promotion-item">
        <p className="promotion-name">{promotion.name}</p>
        <p className="promotion-description">{promotion.description}</p>
      </div>
    </>
  );
}

export default PromotionDetailItem;
