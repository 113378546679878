import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { PromotionsContext } from "../contexts/promotionsContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import {
  appendCategoryDetails,
  duplicateCartItems,
  filterOutDeliveryPromotions,
  findAppliedPromotions,
  removeUnavailablePromotionId,
  sortPromotions,
} from "../helpers/utils";
import {
  orderItems,
  orderSurcharges,
} from "../../../helpers/orderPayloadBuilder";
import { calculateAmountsAndBreakdowns } from "../../../helpers/itemCalculations";
import { promotionsOperations } from "../../../state/features/promotions";

interface IPromotionsProviderProps {
  children: ReactNode;
}

const PromotionsProvider = ({ children }: IPromotionsProviderProps) => {
  const [userAcknowledgedPromotions, setUserAcknowledgedPromotions] = useState<
    any[]
  >([]);
  const [showPromotionBanner, setShowPromotionBanner] = useState(false);

  const { availablePromotions } = useSelector(
    (state: RootState) => state.promotions
  );
  const { items: userCartItems } = useSelector(
    (state: RootState) => state.cart
  );
  const { order_type: orderType } = useSelector(
    (state: RootState) => state.session
  );
  const { company, user, outlet, menu, discount, riderTip } = useSelector(
    (state: RootState) => state
  );
  const { redeemedPoints } = useSelector((state: RootState) => state.loyalty);
  const { selectedArea } = useSelector((state: RootState) => state.areas);
  const { delivery_charge_id } = useSelector(
    (state: RootState) => state.outlet
  );
  const dispatch = useDispatch();

  const cartVerificationPayload = useMemo(() => {
    const cartBreakdowns = calculateAmountsAndBreakdowns(
      userCartItems,
      menu,
      orderType,
      discount || null,
      redeemedPoints || null,
      selectedArea || {},
      delivery_charge_id || null,
      riderTip || 0,
      []
    );
    const { id: online_company_id, currency } = company;
    const { id: online_location_id } = outlet;
    const {
      sapaad_customer_id: customer_id,
      name: user_name = "",
      email: user_email = "",
    } = user;
    const {
      subTotal: sub_total,
      subTotalAfterDiscount: sub_total_after_discount,
      subTotalTaxAfterDiscount: sub_total_tax_after_discount,
      surchargeSubTotal: surcharge_sub_total,
      surchargeBreakDown: surcharges_breakdown,
      taxTotal: tax_total,
      total: total_amount,
    } = cartBreakdowns;
    const discounts =
      discount && Object.keys(discount).length
        ? [
            {
              id: discount.discount_id,
              name: discount.discount_name,
              type: discount.discount_type.toLowerCase(),
              discount_type: discount.discount_type === "PERCENTAGE" ? 1 : 2,
              on_total: discount.on_total,
              rate: discount.discount_rate,
              amount: discount.discountedAmount,
            },
          ]
        : [];
    const paymentData = {
      id: 0,
      payment_type_id: 0,
      payment_type: "",
      amount: 0,
      name: "",
      tips: 0,
    };

    return {
      online_company_id,
      online_location_id,
      scheduled_at: null,
      default_eta: null,
      online_delivery_eta: null,
      note: { user: "", meta: "" },
      surcharges:
        orderSurcharges(
          menu.surcharges,
          menu.taxes,
          orderType,
          selectedArea,
          delivery_charge_id
        ) ?? [],
      items: orderItems(userCartItems || [],[]),
      delivery_address_id: null,
      delivery_area: "",
      complete_address: "",
      discounts,
      redeemed_loyalty: 0,
      remaining_loyalty: 0,
      earned_loyalty: 0,
      has_loyalty_data: false,
      loyalty_as_discount: false,
      sub_total,
      sub_total_after_discount,
      sub_total_tax_after_discount,
      surcharge_sub_total,
      surcharges_breakdown,
      tax_total,
      total_amount,
      order_type: orderType,
      customer_id,
      is_tax_inclusive: menu.is_tax_inclusive,
      pickup_mode: "",
      user_name,
      user_email,
      currency,
      applied_promotions: [],
      payment: paymentData,
    };
  }, [
    JSON.stringify(userCartItems),
    menu,
    orderType,
    discount,
    redeemedPoints,
    selectedArea,
    delivery_charge_id,
    riderTip,
    company,
    user,
  ]);

  const transformedCart = useMemo(() => {
    let transformedCartItems: any[] = [];
    if (userCartItems.length) {
      //appending category information to cart items
      transformedCartItems = userCartItems.map((item: any) => {
        return appendCategoryDetails(item, menu.categories);
      });
      transformedCartItems = removeUnavailablePromotionId(
        transformedCartItems,
        availablePromotions
      );
      transformedCartItems = duplicateCartItems(transformedCartItems);
    }
    return transformedCartItems;
  }, [JSON.stringify(userCartItems), menu.categories, availablePromotions]);

  const appliedPromotionsData = useMemo(() => {
    let appliedPromotions: any[] = [];
    let promotionAppliedCart: any[] = [];
    let orderPromotion: any[] = [];
    let appliablePromotions: any[] = availablePromotions;
    if (orderType !== "delivery") {
      appliablePromotions = filterOutDeliveryPromotions(appliablePromotions);
    }
    if (transformedCart.length && appliablePromotions.length) {
      let sortedPromotions = sortPromotions(availablePromotions);
      let promotionData = findAppliedPromotions(
        transformedCart,
        sortedPromotions
      );
      appliedPromotions = promotionData.appliedPromotions;
      promotionAppliedCart = promotionData.promotionAppliedCart;
      orderPromotion = sortPromotions(promotionData.orderPromotion);
      if (orderPromotion.length) {
        appliedPromotions.push(orderPromotion[0]);
      }
    } else {
      promotionAppliedCart = userCartItems ?? [];
    }
    return {
      appliedPromotions,
      promotionAppliedCart,
      orderPromotion,
    };
  }, [
    JSON.stringify(userCartItems),
    transformedCart,
    availablePromotions,
    orderType,
  ]);

  const newlyAppliedPromotions = useMemo(() => {
    if (appliedPromotionsData.appliedPromotions.length) {
      return appliedPromotionsData.appliedPromotions.filter(
        (promotion) =>
          !userAcknowledgedPromotions.find(
            (acknowledgedPromotion) => acknowledgedPromotion.id === promotion.id
          )
      );
    }
    return [];
  }, [appliedPromotionsData.appliedPromotions, userAcknowledgedPromotions]);

  const promotionCartBreakdowns = useMemo(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      appliedPromotionsData.promotionAppliedCart,
      menu,
      orderType,
      discount || null,
      redeemedPoints || null,
      selectedArea || {},
      delivery_charge_id || null,
      riderTip || 0,
      appliedPromotionsData.orderPromotion
    );
    return amountBreakdowns;
  }, [
    appliedPromotionsData,
    menu,
    orderType,
    discount,
    redeemedPoints,
    selectedArea,
    delivery_charge_id,
    riderTip,
  ]);

  const userAcknowledgedPromotionsHandler = (newpromotions: any[]) => {
    setUserAcknowledgedPromotions((prev) => [...prev, ...newpromotions]); //adding newly acknowledged promotions to existing list
  };

  useEffect(() => {
    const removedPromotions = userAcknowledgedPromotions.filter(
      (acknowledgedPromotion) =>
        !appliedPromotionsData.appliedPromotions.find(
          (promotion) => promotion.id === acknowledgedPromotion.id
        )
    );
    if (removedPromotions.length > 0) {
      const updatedUserAcknowledgedPromotions =
        userAcknowledgedPromotions.filter(
          (acknowledgedPromotion) =>
            !removedPromotions.find(
              (removedPromotion) =>
                removedPromotion.id === acknowledgedPromotion.id
            )
        );
      setUserAcknowledgedPromotions(updatedUserAcknowledgedPromotions);
    }
  }, [appliedPromotionsData.appliedPromotions, userAcknowledgedPromotions]);

  useEffect(() => {
    if (userCartItems.length && availablePromotions.length) {
      setShowPromotionBanner(true);
    } else {
      setShowPromotionBanner(false);
    }
  }, [availablePromotions, JSON.stringify(userCartItems)]);

  useEffect(() => {
    dispatch(
      promotionsOperations.updatedAppliedPromotion(
        appliedPromotionsData.appliedPromotions
      )
    );
  }, [JSON.stringify(appliedPromotionsData.appliedPromotions)]);

  useEffect(() => {
    dispatch(
      promotionsOperations.updatePromotionAppliedCart(
        appliedPromotionsData.promotionAppliedCart
      )
    );
  }, [JSON.stringify(appliedPromotionsData.promotionAppliedCart)]);

  useEffect(() => {
    dispatch(
      promotionsOperations.updateAppliedOrderPromotions(
        appliedPromotionsData.orderPromotion
      )
    );
  }, [JSON.stringify(appliedPromotionsData.orderPromotion)]);

  return (
    <PromotionsContext.Provider
      value={{
        appliedPromotionsData,
        newlyAppliedPromotions,
        cartVerificationPayload,
        userAcknowledgedPromotionsHandler,
        showPromotionBanner,
        promotionCartBreakdowns,
      }}
    >
      {children}
    </PromotionsContext.Provider>
  );
};

export default PromotionsProvider;
