import React, { useMemo } from "react";
import { formatDecimal } from "../../helpers/itemCalculations";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useIntl } from "react-intl";
import { translatedName } from "../../helpers/translations";

//Track order screen order items
const OrderItems = () => {
  const { currency } = useSelector((state: RootState) => state.company);
  const { items: currentOrderItems } = useSelector(
    (state: RootState) => state.orderInProgress
  );
  const intl = useIntl();

  const orderItems = useMemo(() => {
    const itemSubtotal = (item: any) => {
      let itemDiscount = item?.discount?.promotion_id ? item?.discount : null;
      if (itemDiscount) {
        return item.sub_total - (itemDiscount.amount ?? 0);
      }
      return item.sub_total;
    };
    if (currentOrderItems && currentOrderItems.length) {
      return currentOrderItems.map((item: any) => {
        let orderItemSubtotal = itemSubtotal(item) ?? 0;
        return {
          id: item.id,
          name: translatedName(item, intl.locale),
          quantity: item.quantity,
          subTotal: orderItemSubtotal,
          modifiers: item.modifiers,
        };
      });
    }
    return [];
  }, [JSON.stringify(currentOrderItems)]);

  return (
    <div className="order-items">
      {orderItems.map((item: any) => {
        return (
          <div className="item" key={item.id}>
            <div className="item-count">{item.quantity}x</div>
            <div className="item-name">
              <div>{item.name}</div>
              {item.modifiers.length ? (
                <div className="modifiers">
                  {item.modifiers.map((modifier: any, index: number) => {
                    return (
                      <p className="modifier-item" key={index}>
                        {translatedName(modifier, intl.locale)}
                        {modifier.unit_price > 0 ? (
                          <span className="price">
                            +{formatDecimal(modifier.unit_price)} {currency}
                          </span>
                        ) : (
                          " "
                        )}
                        {"x " + modifier.quantity}
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="item-price">
              {currency} {formatDecimal(item.subTotal)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderItems;
