import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import EmailVerification from '../emailVerification';
import ModalCloseIcon from '../../modalCloseIcon';
import { userOperations } from '../../../state/features/user';

interface EmailFieldProps {
  email: string | undefined;
  setUser: (user: { email: string }) => void;
}

const EmailField: React.FC<EmailFieldProps> = ({ email, setUser }) => {
  const [tempEmail, setTempEmail] = useState<string>(email || ''); 
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hidePopup, setHidePopup] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null); // Consolidated error state

  useEffect(() => {
    setTempEmail(email || '');
  }, [email]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempEmail(e.target.value);
    setEmailError(null); // Clear error when user types
  };

  const handleResetEmail = () => {
    setIsEditingEmail(true);
    setTempEmail(email || '');
  };

  const handleSave = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(tempEmail);

    if (!isValid) {
      setEmailError('invalid'); // Set error type
      return;
    }

    if (tempEmail === email) {
      setEmailError('same'); // Set error type
    } else {
      setEmailError(null); // Clear errors and show modal
      setShowModal(true);
    }
  };

  const handleOtpSuccess = () => {
    setIsEditingEmail(false);
    setShowModal(false);
    setHidePopup(false);
    setUser({ email: tempEmail });
  };

  const handleCancel = () => {
    setIsEditingEmail(false);
    setTempEmail(email || '');
    setEmailError(null); // Clear error on cancel
  };

  const handleClose = () => {
    setShowModal(false);
    setHidePopup(false);
  };

  const errorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'invalid':
        return (
          <FormattedMessage
            id="my_account.invalid_email"
            defaultMessage="Invalid email address"
          />
        );
      case 'same':
        return (
          <FormattedMessage
            id="my_account.same_email"
            defaultMessage="This email is already in use."
          />
        );
      default:
        return (
          <FormattedMessage
            id="my_account.something_went_wrong_try_again"
            defaultMessage="Something went wrong. Please try again later."
          />
        );
    }
  };

  return (
    <div className="field email-field">
      <label>
        <FormattedMessage id="my_account.email_address" defaultMessage="Email Address:" />
      </label>
      <div className="input-wrapper">
        <div className='email-container'>
          <input
            type="email"
            value={tempEmail}
            onChange={handleEmailChange}
            disabled={!isEditingEmail}
          />
          {emailError && (
            <p className="error-message">
              {errorMessage(emailError)}
            </p>
          )}
        </div>
        {!isEditingEmail && (
          <button className="reset-btn" onClick={handleResetEmail}>
            <FormattedMessage id="my_account.reset" defaultMessage="Reset" />
          </button>
        )}
        {isEditingEmail && (
          <div className="button-group">
            <button 
              className={`save-btn ${!tempEmail || emailError ? 'disabled' : ''}`} 
              onClick={handleSave} 
              disabled={!tempEmail || emailError}
            >
              <FormattedMessage id="my_account.save" defaultMessage="Save" />
            </button>
            <button className="cancel-btn" onClick={handleCancel}>
              <FormattedMessage id="my_account.cancel" defaultMessage="Cancel" />
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        dialogClassName={hidePopup ? "custom custom-login curved invisible" : "custom custom-login curved"}
        onHide={handleClose}
        centered
        backdropClassName={hidePopup ? "invisible" : ""}
        backdrop={hidePopup ? "static" : true}
      >
        <ModalCloseIcon onClick={handleClose} />
        <EmailVerification
          onClose={handleClose}
          hideModal={setHidePopup}
          userPayload={{ email: tempEmail }} 
          onSuccess={handleOtpSuccess}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  email: state.user.email,
});

const mapDispatchToProps = {
  setUser: userOperations.setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailField);
