// Packages
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronUp,
  faChevronDown,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import {
  updateItemWithCartIdInCart,
  removeItemWithCartId,
  outOfStockItemsList,
} from "../../helpers/cartFunctions";
import {
  formatDecimal,
  calculateAmountsAndBreakdowns,
} from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";
import OutOfStockWarning from "../outOfStockWarning";
import { usePromotionsContext } from "../../hooks/usePromotionsContext";
import { errorOperations } from "../../state/features/error";
import { loaderOperations } from "../../state/features/loader";

interface IOrdersDropdownProps {
  cart: any;
  setCart: any;
  company: any;
  user: any;
  setUser: any;
  menu: any;
  orderType: any;
  areaId: any;
  deliveryAreas: any;
  recentOrder: any;
  availablePromotions: any[];
  validateCart(cart: any[]): Promise<any>;
  showLoader(): void;
  hideLoader(): void;
}

function OrdersDropdown(props: IOrdersDropdownProps) {
  const [cartItems, setCartItems] = useState([] as any);
  const [subTotal, setSubTotal] = useState(0);
  const [amountToReachMinOrderValue, setAmountToReachMinOrderValue] = useState(0);
  const { appliedPromotionsData, cartVerificationPayload } =
    usePromotionsContext();

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      appliedPromotionsData.promotionAppliedCart,
      props.menu,
      props.orderType,
      null
    );
    setCartItems(appliedPromotionsData.promotionAppliedCart);
    setSubTotal(amountBreakdowns.subTotal);
    if (props.deliveryAreas && props.orderType === "delivery") {
      checkMinOrderValue(amountBreakdowns.subTotal);
    }
  }, [appliedPromotionsData.promotionAppliedCart, props.areaId, appliedPromotionsData]);

  const checkMinOrderValue = (totalAmount: any) => {
    let deliveryArea = props.deliveryAreas.find(
      (area: any) => area.id === props.areaId
    );
    if (Number(totalAmount) < Number(deliveryArea?.min_order_value)) {
      setAmountToReachMinOrderValue(
        Number(deliveryArea.min_order_value) - Number(totalAmount)
      );
    } else {
      setAmountToReachMinOrderValue(0)
    }
  };

  const incrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const decrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count - 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const removeItem = (item: any) => {
    removeItemWithCartId(item, props.cart.items, props.setCart);
  };

  const changeItemCount = (count: any, item: any) => {
    item.count = parseInt(count);
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const checkItemCount = (count: any, item: any) => {
    count = parseInt(count);
    if (!count || count < 0) {
      removeItemWithCartId(item, props.cart.items, props.setCart);
    }
  };

  const handleCartValidationFailure = () => {
    dispatch(
      errorOperations.updateError({
        title: intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
        message: intl.formatMessage({
          id: "error.something_went_wrong",
          defaultMessage: "Something went wrong. Please try again later.",
        }),
      })
    );
  };

  const onCheckoutClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { user, setUser, validateCart } = props;
    const { phone_number, dialing_code, proceed_to_checkout } = user;
    if (!phone_number || !dialing_code) {
      e.preventDefault();
      setUser({ ...user, proceed_to_checkout: true });
    } else {
      try {
        props.showLoader();
        const result = await validateCart(cartVerificationPayload);
        if (!result.error && result.payload.data.validated) {
          history.push("/checkout");
        } else {
          props.hideLoader();
          handleCartValidationFailure();
        }
      } catch (error) {
        handleCartValidationFailure();
      } finally {
        props.hideLoader();
      }
    }
  };

  const outOfStockCartItems = useMemo(()=>{
    return outOfStockItemsList(cartItems, props.menu.out_of_stock_items)
  }, [cartItems, props.menu.out_of_stock_items]);

  const findPromotionName = (promoId: number, availablePromotions: any) => {
    let promotion = availablePromotions.find(
      (promotion: any) => promotion.id === promoId
    );
    return promotion?.name;
  }

  const disableCheckoutButton = useMemo(() => {
    return (
      outOfStockCartItems.length ||
      props.recentOrder ||
      amountToReachMinOrderValue
    );
  }, [outOfStockCartItems, props.recentOrder, amountToReachMinOrderValue]);

  return (
    <div className="orders-dropdown-section">
      <div className="out-of-stock-message">
        {outOfStockCartItems.length ? (
          <OutOfStockWarning outOfStockItems={outOfStockCartItems} />
        ) : null}
      </div>
      <div
        className={`${
          outOfStockCartItems.length ? "with-out-of-stock" : ""
        } order-items`}
      >
        {cartItems.map((cartItem: any, index: number) => {
          return (
            <div className="item" key={index}>
              <p
                className={`${
                  props.menu.out_of_stock_items.includes(cartItem.id)
                    ? "out-of-stock-item-name"
                    : ""
                } item-name`}
              >
                {translatedName(cartItem, intl.locale)}
                {cartItem.applied_promotion_id ? (
                  <p className="item-promotion-name">
                    <span>
                      <FontAwesomeIcon icon={faTag} />{" "}
                      {findPromotionName(
                        cartItem.applied_promotion_id,
                        props.availablePromotions
                      )}
                    </span>
                  </p>
                ) : null}
              </p>
              <div className="item-count">
                <input
                  type="number"
                  className="count"
                  value={cartItem.count || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeItemCount(e.target.value, cartItem);
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    checkItemCount(e.target.value, cartItem);
                  }}
                />
                <div className="count-control">
                  <FontAwesomeIcon
                    onClick={() => incrementCount(cartItem)}
                    icon={faChevronUp}
                    className="count-icon"
                  />
                  <FontAwesomeIcon
                    onClick={() => decrementCount(cartItem)}
                    icon={faChevronDown}
                    className={
                      cartItem.count > 1 ? "count-icon" : "count-icon disabled"
                    }
                  />
                </div>
              </div>
              <p className="item-price">
                {props.company.currency}{" "}
                {formatDecimal(cartItem.sub_total || 0)}
              </p>
              <div onClick={() => removeItem(cartItem)} className="delete-icon">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="amount-section">
        <p>
          <FormattedMessage
            id="global.uppercase.total"
            defaultMessage="TOTAL"
          />
        </p>
        <p>
          {props.company.currency} {formatDecimal(subTotal)}
        </p>
      </div>
      <button
        disabled={disableCheckoutButton}
        onClick={(event) => onCheckoutClick(event)}
        id="goToCheckoutFromCartBtn"
        className={
          disableCheckoutButton
            ? "button btn btn-secondary disabled w-100"
            : "button btn btn-primary w-100"
        }
      >
        {amountToReachMinOrderValue ? (
          <FormattedMessage
            id="checkout.add_more_to_checkout"
            defaultMessage="Add {currency} {amount} more to Checkout"
            values={{
              currency: props.company.currency,
              amount: formatDecimal(amountToReachMinOrderValue),
            }}
          />
        ) : (
          <FormattedMessage id="global.checkout" defaultMessage="Checkout" />
        )}
      </button>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let user = state.user || {};
  let menu = state.menu;
  let cart = state.cart;
  let orderType = state.session.order_type;
  let areaId = state.session.area_id;
  let deliveryAreas = state.outlet?.delivery_areas;
  let recentOrder = state.recentOrder;
  let availablePromotions = state.promotions.availablePromotions ?? [];

  return {
    company,
    user,
    cart,
    menu,
    orderType,
    areaId,
    deliveryAreas,
    recentOrder,
    availablePromotions,
  };
};

const mapDispatchToProps = {
  setUser: userOperations.setUser,
  setCart: cartOperations.setCart,
  validateCart: cartOperations.validateCart,
  error: errorOperations.updateError,
  showLoader: loaderOperations.showLoader,
  hideLoader: loaderOperations.hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDropdown);
