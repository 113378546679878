// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import SavedAddressesMobile from "../../mobileComponents/savedAddressesMobile";
import SavedPaymentsMobile from "../../mobileComponents/savedPaymentsMobile";
import PersonalDetailsMobile from "../../mobileComponents/personalDetailsMobile";
// Redux Operations
import { pageOperations } from "../../state/features/page";

interface IMyProfileScreenProps {
  fetchProfileConfigurations: any;
}
interface IMyProfileScreenState {}

class MyProfileScreen extends Component<
  IMyProfileScreenProps,
  IMyProfileScreenState
> {
  constructor(props: IMyProfileScreenProps) {
    super(props);
    this.state = {};

    this.props.fetchProfileConfigurations();
  }

  render() {
    return (
      <React.Fragment>
        <div className="my-profile-screen">
          <h1 className="main-heading">
            <FormattedMessage
              id="sidemenu.my_account"
              defaultMessage="My Account"
            />
          </h1>

          <PersonalDetailsMobile />
          <SavedAddressesMobile />
          <SavedPaymentsMobile />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchProfileConfigurations: pageOperations.fetchProfileConfigurations,
};

export default connect(null, mapDispatchToProps)(MyProfileScreen);
